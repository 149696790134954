import React, { FC, ReactNode, useMemo } from "react"
import { SpeedInsights } from "@vercel/speed-insights/react"

// Shared Components
import SiteFooter from "../site/SiteFooter/SiteFooter"
import SiteFooterSmall from "../site/SiteFooterSmall/SiteFooterSmall"
import SiteNav from "@components/site/SiteNav/SiteNav"

// Sub-components
import Beacon from "@components/Beacon/Beacon"
import FixedCtaBar, { FIXED_CTA_BAR_VARIANTS } from "@components/FixedCtaBar/FixedCtaBar"
import HashHandler from "./components/HashHandler/HashHandler"
import NinetailedListener from "./components/NinetailedListener/NinetailedListener"
import QueryStringHandler from "./components/QueryStringHandler/QueryStringHandler"

// Consts
import {
  USE_SMALL_FOOTER_PATHS,
  HIDE_FOOTER_PATHS,
  INCLUDE_FIXED_CTA_BAR_PATHS_REGEX,
  USE_STATIC_HEADER_PATHS_REGEX,
} from "./Layout.constants"

// Types/interfaces
interface LayoutProps {
  children: ReactNode
  path: string
  template?: string
  useStaticHeader?: boolean
}

interface LayoutInternalVars {
  hideFooter: boolean
  includeFixedCtaBar: boolean
  isHomePage: boolean
  useSmallFooter: boolean
  useStaticHeader: boolean
}

// Component
const Layout: FC<LayoutProps> = ({
  children,
  path,
  template,
  useStaticHeader: _useStaticHeader,
}) => {
  const {
    hideFooter,
    includeFixedCtaBar,
    isHomePage,
    useSmallFooter,
    useStaticHeader,
  }: LayoutInternalVars = useMemo(
    (): LayoutInternalVars => ({
      hideFooter: HIDE_FOOTER_PATHS.includes(path),
      includeFixedCtaBar: !!path.match(INCLUDE_FIXED_CTA_BAR_PATHS_REGEX),
      isHomePage: path === "/",
      useSmallFooter: USE_SMALL_FOOTER_PATHS.includes(path),
      useStaticHeader: _useStaticHeader || !!path.match(USE_STATIC_HEADER_PATHS_REGEX),
    }),
    [path, template]
  )

  return (
    <>
      <HashHandler />
      <QueryStringHandler />
      <NinetailedListener path={path} />
      <SiteNav
        isStatic={useStaticHeader}
        includeLoginButton={isHomePage}
      />
      <main>{children}</main>
      {!hideFooter && (useSmallFooter ? <SiteFooterSmall /> : <SiteFooter />)}
      {includeFixedCtaBar && (
        <FixedCtaBar
          variant={
            path === "/" ?
              FIXED_CTA_BAR_VARIANTS.TRIAL :
              FIXED_CTA_BAR_VARIANTS.DEMO
          }
        />
      )}
      <Beacon />
      <SpeedInsights />
    </>
  )
}

export default Layout
