module.exports = [{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"domain":"hs-marketing-contentful.imgix.net","secureURLToken":"afCADBW5cKfmmeeJ","defaultImgixParams":{"auto":"format, compress","q":75},"fields":[{"nodeType":"ContentfulAsset","fieldName":"imgixImage","rawURLKey":"file.url","URLPrefix":"https:"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Help Scout","short_name":"Help Scout","start_url":"/","background_color":"#ffffff","theme_color":"#4176fa","icons":[{"src":"static/images/favicon/android-chrome-192x192.png","type":"image/png","sizes":"192x192"},{"src":"static/images/favicon/android-chrome-512x512.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/@ninetailed/experience.js-gatsby/gatsby-browser.js'),
      options: {"plugins":[],"clientId":"d85f4de0-1a95-4d34-91c7-34063991a421","componentViewTrackingThreshold":1000,"ninetailedPlugins":[{"resolve":"@ninetailed/experience.js-plugin-insights"},{"resolve":"@ninetailed/experience.js-plugin-google-tagmanager"}],"useSDKEvaluation":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
