import { Script } from "gatsby"
import React, { ReactElement } from "react"

const GA_SCRIPT: string = `
<!-- Google tag (gtag.js) -->
if (!window.gtag) {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-ZL3HLDLQXR');
}

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TTVS5W5R');
`

const GaScript = (): ReactElement => (
  <Script
    id="GoogleAnalyticsScript"
    dangerouslySetInnerHTML={{ __html: GA_SCRIPT }}
  />
)

export default GaScript
