export enum ICON_TYPES {
  "16-plus" = "16-plus",
  "18-plus" = "18-plus",
  "2m-distance" = "2m-distance",
  "3d-rotate" = "3d-rotate",
  "4g" = "4g",
  "4k" = "4k",
  "5g" = "5g",
  "90-degrees" = "90-degrees",
  "a-z-sort" = "a-z-sort",
  "accessibility" = "accessibility",
  "acorn" = "acorn",
  "activity" = "activity",
  "add-video" = "add-video",
  "aerial" = "aerial",
  "airplay-to-tv" = "airplay-to-tv",
  "airpods" = "airpods",
  "alarm-warning" = "alarm-warning",
  "alarm" = "alarm",
  "alert" = "alert",
  "alert-error" = "alert-error",
  "alien-2" = "alien-2",
  "alien" = "alien",
  "align-arrow-down" = "align-arrow-down",
  "align-arrow-left" = "align-arrow-left",
  "align-arrow-right" = "align-arrow-right",
  "align-arrow-up" = "align-arrow-up",
  "align-bottom" = "align-bottom",
  "align-centre-horizontal" = "align-centre-horizontal",
  "align-centre-vertical" = "align-centre-vertical",
  "align-horizontal-centre" = "align-horizontal-centre",
  "align-left" = "align-left",
  "align-right" = "align-right",
  "align-top" = "align-top",
  "align-vertical-centre" = "align-vertical-centre",
  "alt-key" = "alt-key",
  "anchor" = "anchor",
  "angle" = "angle",
  "animation-enter" = "animation-enter",
  "animation-exit" = "animation-exit",
  "annotation-dots" = "annotation-dots",
  "annotation-warning" = "annotation-warning",
  "annotation" = "annotation",
  "announcement" = "announcement",
  "app-store" = "app-store",
  "apple-2" = "apple-2",
  "apple-pay" = "apple-pay",
  "apple-watch-ultra" = "apple-watch-ultra",
  "apple-watch" = "apple-watch",
  "apple" = "apple",
  "apps" = "apps",
  "aquarius" = "aquarius",
  "archive-arrow-down" = "archive-arrow-down",
  "archive-arrow-up" = "archive-arrow-up",
  "archive-check" = "archive-check",
  "archive-minus" = "archive-minus",
  "archive-plus" = "archive-plus",
  "archive-stack" = "archive-stack",
  "archive" = "archive",
  "aries" = "aries",
  "arrow-down-circle" = "arrow-down-circle",
  "arrow-down-left-circle" = "arrow-down-left-circle",
  "arrow-down-left" = "arrow-down-left",
  "arrow-down-right-circle" = "arrow-down-right-circle",
  "arrow-down-right" = "arrow-down-right",
  "arrow-down" = "arrow-down",
  "arrow-left-circle" = "arrow-left-circle",
  "arrow-left" = "arrow-left",
  "arrow-right-circle" = "arrow-right-circle",
  "arrow-right" = "arrow-right",
  "arrow-up-circle" = "arrow-up-circle",
  "arrow-up-left-circle" = "arrow-up-left-circle",
  "arrow-up-left" = "arrow-up-left",
  "arrow-up-right-circle" = "arrow-up-right-circle",
  "arrow-up-right" = "arrow-up-right",
  "arrow-up" = "arrow-up",
  "arrows" = "arrows",
  "asterisk" = "asterisk",
  "astronomy" = "astronomy",
  "at-sign" = "at-sign",
  "avatar-hexagonal" = "avatar-hexagonal",
  "avatar-square" = "avatar-square",
  "award" = "award",
  "axe" = "axe",
  "back" = "back",
  "backpack" = "backpack",
  "bacteria" = "bacteria",
  "badge-2" = "badge-2",
  "badge" = "badge",
  "baguette" = "baguette",
  "baht" = "baht",
  "balance-sheet" = "balance-sheet",
  "ball-rolling-fast" = "ball-rolling-fast",
  "ball-rolling" = "ball-rolling",
  "ball" = "ball",
  "balloon" = "balloon",
  "balloons" = "balloons",
  "ballot-box" = "ballot-box",
  "ballot" = "ballot",
  "banana" = "banana",
  "band-aid" = "band-aid",
  "bandages" = "bandages",
  "bank-note" = "bank-note",
  "bank" = "bank",
  "barcode-2" = "barcode-2",
  "barcode" = "barcode",
  "baseball" = "baseball",
  "bath" = "bath",
  "battery-charging" = "battery-charging",
  "battery-error" = "battery-error",
  "battery-full" = "battery-full",
  "battery-low" = "battery-low",
  "battery-medium" = "battery-medium",
  "battery" = "battery",
  "bbq" = "bbq",
  "beach-2" = "beach-2",
  "beach" = "beach",
  "bed" = "bed",
  "beer" = "beer",
  "bell-check" = "bell-check",
  "bell-cross" = "bell-cross",
  "bell-off" = "bell-off",
  "bell-ringing" = "bell-ringing",
  "bell-snooze" = "bell-snooze",
  "bell" = "bell",
  "bezier-curve" = "bezier-curve",
  "bicycle" = "bicycle",
  "binoculars" = "binoculars",
  "bird-house" = "bird-house",
  "bird" = "bird",
  "bitcoin" = "bitcoin",
  "blinds-closed" = "blinds-closed",
  "blinds-open" = "blinds-open",
  "block-inside" = "block-inside",
  "block-outside" = "block-outside",
  "blockquote" = "blockquote",
  "bluetooth" = "bluetooth",
  "boat" = "boat",
  "bold" = "bold",
  "bolt" = "bolt",
  "bone" = "bone",
  "book-2" = "book-2",
  "book-open" = "book-open",
  "book" = "book",
  "bookmark-2" = "bookmark-2",
  "bookmark" = "bookmark",
  "books" = "books",
  "borders" = "borders",
  "bottle" = "bottle",
  "bowl" = "bowl",
  "bowling" = "bowling",
  "box" = "box",
  "boxes" = "boxes",
  "brain" = "brain",
  "bread" = "bread",
  "brick-wall" = "brick-wall",
  "briefcase-2" = "briefcase-2",
  "briefcase" = "briefcase",
  "brightness-high" = "brightness-high",
  "brightness-low" = "brightness-low",
  "brightness-medium" = "brightness-medium",
  "bring-forward" = "bring-forward",
  "broadcast" = "broadcast",
  "broken-bone" = "broken-bone",
  "browser-history" = "browser-history",
  "browser-tabs" = "browser-tabs",
  "browser" = "browser",
  "bug" = "bug",
  "building-01" = "building-01",
  "building-2" = "building-2",
  "building-store" = "building-store",
  "building" = "building",
  "buildings" = "buildings",
  "bunting-2" = "bunting-2",
  "bunting" = "bunting",
  "burger" = "burger",
  "bus" = "bus",
  "cabinet-2" = "cabinet-2",
  "cabinet" = "cabinet",
  "cake-slice" = "cake-slice",
  "cake" = "cake",
  "calculator" = "calculator",
  "calendar-check" = "calendar-check",
  "calendar-cross" = "calendar-cross",
  "calendar-minus" = "calendar-minus",
  "calendar-plus" = "calendar-plus",
  "calendar-timer" = "calendar-timer",
  "calendar" = "calendar",
  "camera-off" = "camera-off",
  "camera-tripod" = "camera-tripod",
  "camera" = "camera",
  "cancer" = "cancer",
  "capricorn" = "capricorn",
  "captions" = "captions",
  "car-2" = "car-2",
  "car-battery" = "car-battery",
  "car-dashboard" = "car-dashboard",
  "car-door" = "car-door",
  "car-engine" = "car-engine",
  "car-gear-stick" = "car-gear-stick",
  "car" = "car",
  "carat-down" = "carat-down",
  "carat-left" = "carat-left",
  "carat-right" = "carat-right",
  "carat-up" = "carat-up",
  "cards" = "cards",
  "cart" = "cart",
  "cassette" = "cassette",
  "cat" = "cat",
  "cctv" = "cctv",
  "celsius" = "celsius",
  "cent" = "cent",
  "center" = "center",
  "chart-decrease" = "chart-decrease",
  "chart-increase" = "chart-increase",
  "chart" = "chart",
  "check-2" = "check-2",
  "check-circle" = "check-circle",
  "check" = "check",
  "checklist-2" = "checklist-2",
  "checklist" = "checklist",
  "checkmark" = "checkmark",
  "cheese" = "cheese",
  "chemical-bottle" = "chemical-bottle",
  "chemical-tube-2" = "chemical-tube-2",
  "chemical-tube" = "chemical-tube",
  "cherry" = "cherry",
  "chevron-down" = "chevron-down",
  "chevron-left" = "chevron-left",
  "chevron-right" = "chevron-right",
  "chevron-up" = "chevron-up",
  "chicken" = "chicken",
  "chilli" = "chilli",
  "christmas-tree" = "christmas-tree",
  "circle-intersect" = "circle-intersect",
  "circle-plus" = "circle-plus",
  "circle" = "circle",
  "clean-power" = "clean-power",
  "clean" = "clean",
  "click-pulse" = "click-pulse",
  "clickable-area" = "clickable-area",
  "clipboard" = "clipboard",
  "clock" = "clock",
  "close-circle" = "close-circle",
  "close" = "close",
  "closed-captions-off" = "closed-captions-off",
  "closed-captions" = "closed-captions",
  "cloud" = "cloud",
  "clubs" = "clubs",
  "coat-hanger" = "coat-hanger",
  "cocktail" = "cocktail",
  "code-2" = "code-2",
  "code-3" = "code-3",
  "code" = "code",
  "coffee-machine" = "coffee-machine",
  "coffin" = "coffin",
  "coins" = "coins",
  "color-swatch" = "color-swatch",
  "columns-2" = "columns-2",
  "columns-horizontal" = "columns-horizontal",
  "columns-vertical" = "columns-vertical",
  "columns" = "columns",
  "command" = "command",
  "compass" = "compass",
  "components" = "components",
  "cone-2" = "cone-2",
  "cone" = "cone",
  "construction" = "construction",
  "contacts" = "contacts",
  "contract" = "contract",
  "copy" = "copy",
  "corner-flag" = "corner-flag",
  "cpu" = "cpu",
  "credit-card-plus" = "credit-card-plus",
  "credit-card" = "credit-card",
  "crop" = "crop",
  "crosshair-2" = "crosshair-2",
  "crosshair" = "crosshair",
  "crowd" = "crowd",
  "crown-2" = "crown-2",
  "crown" = "crown",
  "crucifix" = "crucifix",
  "css" = "css",
  "cup-straw" = "cup-straw",
  "cursor-click" = "cursor-click",
  "cursor-text" = "cursor-text",
  "cursor" = "cursor",
  "dashboard" = "dashboard",
  "database-error" = "database-error",
  "database-minus" = "database-minus",
  "database-plus" = "database-plus",
  "database" = "database",
  "deathstar" = "deathstar",
  "deer" = "deer",
  "delete" = "delete",
  "devices" = "devices",
  "dial-pad" = "dial-pad",
  "diamonds" = "diamonds",
  "dice-1" = "dice-1",
  "dice-2" = "dice-2",
  "dice-3" = "dice-3",
  "dice-4" = "dice-4",
  "dice-5" = "dice-5",
  "dice-6" = "dice-6",
  "dimensions" = "dimensions",
  "directions" = "directions",
  "disability-2" = "disability-2",
  "disability" = "disability",
  "discord" = "discord",
  "dna-2" = "dna-2",
  "dna" = "dna",
  "doc" = "doc",
  "dog" = "dog",
  "dogecoin-2" = "dogecoin-2",
  "dogecoin" = "dogecoin",
  "dollar-2" = "dollar-2",
  "dollar" = "dollar",
  "door-enter" = "door-enter",
  "door-exit" = "door-exit",
  "door-lock" = "door-lock",
  "door-open" = "door-open",
  "door" = "door",
  "dots-horizontal-circle" = "dots-horizontal-circle",
  "dots-horizontal" = "dots-horizontal",
  "dots-vertical-circle" = "dots-vertical-circle",
  "dots-vertical" = "dots-vertical",
  "dots" = "dots",
  "dotted-circle-left" = "dotted-circle-left",
  "dotted-circle-right" = "dotted-circle-right",
  "double-bed" = "double-bed",
  "double-chat-bubble" = "double-chat-bubble",
  "double-chevron-down" = "double-chevron-down",
  "double-chevron-left" = "double-chevron-left",
  "double-chevron-right" = "double-chevron-right",
  "double-chevron-up" = "double-chevron-up",
  "download-cloud" = "download-cloud",
  "download" = "download",
  "downstairs" = "downstairs",
  "dress" = "dress",
  "dribbble" = "dribbble",
  "drill" = "drill",
  "drink" = "drink",
  "drone" = "drone",
  "dropbox" = "dropbox",
  "dropdown" = "dropdown",
  "droplet-off" = "droplet-off",
  "droplet" = "droplet",
  "droplets" = "droplets",
  "drums-2" = "drums-2",
  "drums" = "drums",
  "dumbell" = "dumbell",
  "ear-off" = "ear-off",
  "ear" = "ear",
  "east" = "east",
  "edit-shape" = "edit-shape",
  "edit" = "edit",
  "education" = "education",
  "egg-timer" = "egg-timer",
  "electric-bike" = "electric-bike",
  "electric-scooter" = "electric-scooter",
  "email-error" = "email-error",
  "email-minus" = "email-minus",
  "email-plus" = "email-plus",
  "emoji-angry" = "emoji-angry",
  "emoji-happy" = "emoji-happy",
  "emoji-neutral" = "emoji-neutral",
  "emoji-sad" = "emoji-sad",
  "emoji-x-eyes" = "emoji-x-eyes",
  "eraser" = "eraser",
  "esc-key" = "esc-key",
  "ethereum" = "ethereum",
  "euro" = "euro",
  "export" = "export",
  "eye-dropper" = "eye-dropper",
  "eye-lock" = "eye-lock",
  "eye-off" = "eye-off",
  "eye" = "eye",
  "face-angel" = "face-angel",
  "face-big-smile-1" = "face-big-smile-1",
  "face-big-smile" = "face-big-smile",
  "face-check" = "face-check",
  "face-cry" = "face-cry",
  "face-id-error" = "face-id-error",
  "face-id" = "face-id",
  "face-laughing" = "face-laughing",
  "face-love" = "face-love",
  "face-sad-2" = "face-sad-2",
  "face-sad" = "face-sad",
  "face-shock" = "face-shock",
  "face-smile-circle" = "face-smile-circle",
  "face-straight" = "face-straight",
  "face-sweat" = "face-sweat",
  "face-upside-down" = "face-upside-down",
  "face-whistle" = "face-whistle",
  "face-yawn" = "face-yawn",
  "face" = "face",
  "facebook" = "facebook",
  "fahrenheit" = "fahrenheit",
  "fan-2" = "fan-2",
  "fan" = "fan",
  "fast-forward" = "fast-forward",
  "fast-train" = "fast-train",
  "faucet" = "faucet",
  "feather" = "feather",
  "female-1" = "female-1",
  "female" = "female",
  "figma" = "figma",
  "file-2" = "file-2",
  "file-checkmark" = "file-checkmark",
  "file-download-2" = "file-download-2",
  "file-error" = "file-error",
  "file-important" = "file-important",
  "file-minus-2" = "file-minus-2",
  "file-minus" = "file-minus",
  "file-plus-2" = "file-plus-2",
  "file-plus" = "file-plus",
  "file-scan" = "file-scan",
  "file-search" = "file-search",
  "file-share" = "file-share",
  "file-text-2" = "file-text-2",
  "file-text" = "file-text",
  "file-user" = "file-user",
  "file" = "file",
  "files" = "files",
  "film" = "film",
  "filter-2" = "filter-2",
  "filter" = "filter",
  "filters-2" = "filters-2",
  "filters" = "filters",
  "finder" = "finder",
  "finger-cross" = "finger-cross",
  "finger-point" = "finger-point",
  "finger-swipe" = "finger-swipe",
  "finger-tap" = "finger-tap",
  "fingerprint-2" = "fingerprint-2",
  "fingerprint" = "fingerprint",
  "fire" = "fire",
  "fish" = "fish",
  "fishes" = "fishes",
  "flag-priority-2" = "flag-priority-2",
  "flag-priority-3" = "flag-priority-3",
  "flag-priority" = "flag-priority",
  "flag" = "flag",
  "flashlight" = "flashlight",
  "flip-horizontal" = "flip-horizontal",
  "flip-horizontally" = "flip-horizontally",
  "flip-vertical" = "flip-vertical",
  "flip-vertically" = "flip-vertically",
  "float-center" = "float-center",
  "float-left" = "float-left",
  "float-right" = "float-right",
  "floorplan" = "floorplan",
  "floppy-disc" = "floppy-disc",
  "flower" = "flower",
  "flowers" = "flowers",
  "folder-check" = "folder-check",
  "folder-cross" = "folder-cross",
  "folder-down" = "folder-down",
  "folder-important" = "folder-important",
  "folder-minus" = "folder-minus",
  "folder-open-2" = "folder-open-2",
  "folder-open" = "folder-open",
  "folder-person" = "folder-person",
  "folder-plus" = "folder-plus",
  "folder-search" = "folder-search",
  "folder-share" = "folder-share",
  "folder-star" = "folder-star",
  "folder-up" = "folder-up",
  "folder-zip" = "folder-zip",
  "folder" = "folder",
  "font-family" = "font-family",
  "font-size" = "font-size",
  "food-can" = "food-can",
  "forbid-2" = "forbid-2",
  "forbid" = "forbid",
  "fork-2" = "fork-2",
  "fork" = "fork",
  "forward" = "forward",
  "fountain-pen" = "fountain-pen",
  "frame" = "frame",
  "framer" = "framer",
  "frying-pan" = "frying-pan",
  "fullscreen" = "fullscreen",
  "gallery-view" = "gallery-view",
  "gaming" = "gaming",
  "garage" = "garage",
  "garden-hose" = "garden-hose",
  "gauge" = "gauge",
  "gavel" = "gavel",
  "gbp" = "gbp",
  "gemini" = "gemini",
  "ghost" = "ghost",
  "gif" = "gif",
  "gift" = "gift",
  "git-branch" = "git-branch",
  "git-commit" = "git-commit",
  "git-diff" = "git-diff",
  "git-fork" = "git-fork",
  "git-merge" = "git-merge",
  "git-pull-request" = "git-pull-request",
  "github" = "github",
  "giving-money" = "giving-money",
  "giving" = "giving",
  "glass-spill" = "glass-spill",
  "globe" = "globe",
  "gmail" = "gmail",
  "golden-gate-bridge" = "golden-gate-bridge",
  "golf-ball" = "golf-ball",
  "golf" = "golf",
  "google-drive" = "google-drive",
  "google" = "google",
  "grapes" = "grapes",
  "gravestone" = "gravestone",
  "grid-2" = "grid-2",
  "grid-3" = "grid-3",
  "grid-masonry-2" = "grid-masonry-2",
  "grid-masonry" = "grid-masonry",
  "grid" = "grid",
  "guitar" = "guitar",
  "gun" = "gun",
  "hair-clippers" = "hair-clippers",
  "hair-pin" = "hair-pin",
  "hairdryer" = "hairdryer",
  "half-circle-bottom" = "half-circle-bottom",
  "half-circle-top" = "half-circle-top",
  "hammer" = "hammer",
  "hand-important" = "hand-important",
  "hand-lock" = "hand-lock",
  "hand-minus" = "hand-minus",
  "hand-plus" = "hand-plus",
  "hand" = "hand",
  "hard-drive-2" = "hard-drive-2",
  "hard-drive-storage" = "hard-drive-storage",
  "hard-drive" = "hard-drive",
  "hashtag" = "hashtag",
  "hat" = "hat",
  "heading" = "heading",
  "headphones-2" = "headphones-2",
  "headphones-3" = "headphones-3",
  "headphones" = "headphones",
  "headset" = "headset",
  "health" = "health",
  "heart-minus" = "heart-minus",
  "heart-plus" = "heart-plus",
  "heart-rate" = "heart-rate",
  "heart" = "heart",
  "hearts" = "hearts",
  "height" = "height",
  "helicopter" = "helicopter",
  "help-circle" = "help-circle",
  "help" = "help",
  "highlighter" = "highlighter",
  "home-wifi" = "home-wifi",
  "home" = "home",
  "hook" = "hook",
  "hourglass" = "hourglass",
  "hubspot" = "hubspot",
  "humidity-high" = "humidity-high",
  "humidity-low" = "humidity-low",
  "humidity-sensor" = "humidity-sensor",
  "ice-cream" = "ice-cream",
  "ice" = "ice",
  "id-card" = "id-card",
  "inbox" = "inbox",
  "incognito" = "incognito",
  "indent-left" = "indent-left",
  "indent-right" = "indent-right",
  "information" = "information",
  "ingredients" = "ingredients",
  "input-field" = "input-field",
  "instagram" = "instagram",
  "iphone-2" = "iphone-2",
  "iphone-3" = "iphone-3",
  "iphone-dual-camera" = "iphone-dual-camera",
  "iphone-pro-camera" = "iphone-pro-camera",
  "iphone" = "iphone",
  "iron" = "iron",
  "italic" = "italic",
  "jetski" = "jetski",
  "jpg" = "jpg",
  "kerning" = "kerning",
  "kettle-2" = "kettle-2",
  "kettle" = "kettle",
  "key" = "key",
  "keyboard-2" = "keyboard-2",
  "keyboard" = "keyboard",
  "keyhole" = "keyhole",
  "keys" = "keys",
  "kiosk" = "kiosk",
  "knife-2" = "knife-2",
  "knife" = "knife",
  "ladder-2" = "ladder-2",
  "ladder" = "ladder",
  "lamp" = "lamp",
  "landscape" = "landscape",
  "language-1" = "language-1",
  "language" = "language",
  "languages" = "languages",
  "laptop-2" = "laptop-2",
  "laptop-and-phone" = "laptop-and-phone",
  "laptop-check" = "laptop-check",
  "laptop-error" = "laptop-error",
  "laptop" = "laptop",
  "layer-1" = "layer-1",
  "layer-2" = "layer-2",
  "layer-3" = "layer-3",
  "layers" = "layers",
  "layout-bottom" = "layout-bottom",
  "layout-left" = "layout-left",
  "layout-right" = "layout-right",
  "layout-top" = "layout-top",
  "leaderboard" = "leaderboard",
  "leaf-2" = "leaf-2",
  "leaf-3" = "leaf-3",
  "leaf" = "leaf",
  "lego" = "lego",
  "leo" = "leo",
  "letter-a-circle" = "letter-a-circle",
  "letter-b-circle" = "letter-b-circle",
  "letter-c-circle" = "letter-c-circle",
  "letter-spacing" = "letter-spacing",
  "libra" = "libra",
  "life-jacket" = "life-jacket",
  "light-bulb" = "light-bulb",
  "light-ceiling" = "light-ceiling",
  "light-off" = "light-off",
  "light-switch" = "light-switch",
  "lightning" = "lightning",
  "line-height-2" = "line-height-2",
  "line-height" = "line-height",
  "link-break" = "link-break",
  "link" = "link",
  "linked" = "linked",
  "linkedin" = "linkedin",
  "livestream" = "livestream",
  "loading" = "loading",
  "location" = "location",
  "lock-unlocked" = "lock-unlocked",
  "lock" = "lock",
  "log-in" = "log-in",
  "log-out" = "log-out",
  "love" = "love",
  "luggage-carousel" = "luggage-carousel",
  "lungs" = "lungs",
  "magic-hat" = "magic-hat",
  "magic-mouse" = "magic-mouse",
  "magnet-bolt" = "magnet-bolt",
  "magnet" = "magnet",
  "mail-open-cross-1" = "mail-open-cross-1",
  "mail-open-cross" = "mail-open-cross",
  "mail-open" = "mail-open",
  "mail" = "mail",
  "mailbox" = "mailbox",
  "male-1" = "male-1",
  "male" = "male",
  "map-2" = "map-2",
  "map" = "map",
  "mask-2" = "mask-2",
  "mask" = "mask",
  "maximize-2" = "maximize-2",
  "maximize" = "maximize",
  "medical" = "medical",
  "menu-2" = "menu-2",
  "menu" = "menu",
  "message" = "message",
  "microphone-2" = "microphone-2",
  "microphone-mute" = "microphone-mute",
  "microphone" = "microphone",
  "microwave" = "microwave",
  "milk" = "milk",
  "mini-fridge" = "mini-fridge",
  "minimize-2" = "minimize-2",
  "minimize" = "minimize",
  "minus-circle" = "minus-circle",
  "minus" = "minus",
  "mirror-1" = "mirror-1",
  "mirror-horizontal" = "mirror-horizontal",
  "mirror" = "mirror",
  "money-bag" = "money-bag",
  "money" = "money",
  "monitor-1" = "monitor-1",
  "monitor" = "monitor",
  "moon-2" = "moon-2",
  "moon" = "moon",
  "motion-sensor" = "motion-sensor",
  "mountain" = "mountain",
  "mouse" = "mouse",
  "move" = "move",
  "mug-2" = "mug-2",
  "mug-3" = "mug-3",
  "mug" = "mug",
  "multiple-folders" = "multiple-folders",
  "music-note" = "music-note",
  "music" = "music",
  "navigation" = "navigation",
  "needle" = "needle",
  "network" = "network",
  "new-hire" = "new-hire",
  "new" = "new",
  "newspaper" = "newspaper",
  "no-avatar-square" = "no-avatar-square",
  "no-avatar" = "no-avatar",
  "no-location" = "no-location",
  "no-parking" = "no-parking",
  "no-pin" = "no-pin",
  "no-weed" = "no-weed",
  "nordvpn" = "nordvpn",
  "north" = "north",
  "nose-bleed" = "nose-bleed",
  "nose" = "nose",
  "notebook" = "notebook",
  "nuclear" = "nuclear",
  "nut" = "nut",
  "octagon" = "octagon",
  "one-finger" = "one-finger",
  "onion" = "onion",
  "open-pane" = "open-pane",
  "option" = "option",
  "ordered-list" = "ordered-list",
  "package" = "package",
  "padding" = "padding",
  "page-flip" = "page-flip",
  "paintbrush-2" = "paintbrush-2",
  "paintbrush-3" = "paintbrush-3",
  "paintbrush" = "paintbrush",
  "paintbucket-2" = "paintbucket-2",
  "paintbucket-3" = "paintbucket-3",
  "paintbucket" = "paintbucket",
  "paperclip" = "paperclip",
  "parenthesis" = "parenthesis",
  "parking" = "parking",
  "party" = "party",
  "passport" = "passport",
  "password" = "password",
  "pause" = "pause",
  "pawn" = "pawn",
  "pdf" = "pdf",
  "peace" = "peace",
  "pen-tool-2" = "pen-tool-2",
  "pen-tool" = "pen-tool",
  "pen" = "pen",
  "pencil-2" = "pencil-2",
  "pencil-book" = "pencil-book",
  "pencil" = "pencil",
  "people" = "people",
  "pepper" = "pepper",
  "percentage" = "percentage",
  "periodic-table" = "periodic-table",
  "person-check" = "person-check",
  "person-cross" = "person-cross",
  "person-with-luggage" = "person-with-luggage",
  "person" = "person",
  "petrol" = "petrol",
  "pharmacy" = "pharmacy",
  "phone-call-cross" = "phone-call-cross",
  "phone-call-forward" = "phone-call-forward",
  "phone-call-hang-up" = "phone-call-hang-up",
  "phone-call-incoming" = "phone-call-incoming",
  "phone-call-outgoing" = "phone-call-outgoing",
  "phone-call" = "phone-call",
  "phone-check" = "phone-check",
  "phone-signal" = "phone-signal",
  "phone" = "phone",
  "photo-error" = "photo-error",
  "photo-film" = "photo-film",
  "photo-frame" = "photo-frame",
  "photo-hide" = "photo-hide",
  "photo-lens" = "photo-lens",
  "photo-minus" = "photo-minus",
  "photo-plus" = "photo-plus",
  "photo" = "photo",
  "photoshop" = "photoshop",
  "picture-in-picture" = "picture-in-picture",
  "pie-chart" = "pie-chart",
  "piggybank" = "piggybank",
  "pill-2" = "pill-2",
  "pill" = "pill",
  "pills" = "pills",
  "pin-on-map" = "pin-on-map",
  "pin-tack-2" = "pin-tack-2",
  "pin-tack-3" = "pin-tack-3",
  "pin-tack" = "pin-tack",
  "pin" = "pin",
  "pipe" = "pipe",
  "pisces" = "pisces",
  "pizza-2" = "pizza-2",
  "pizza" = "pizza",
  "plane-2" = "plane-2",
  "plane-landing" = "plane-landing",
  "plane-take-off" = "plane-take-off",
  "plane" = "plane",
  "planet" = "planet",
  "play" = "play",
  "plug-in" = "plug-in",
  "plug-off" = "plug-off",
  "plug-on" = "plug-on",
  "plug" = "plug",
  "plus-circle" = "plus-circle",
  "plus-minus" = "plus-minus",
  "plus" = "plus",
  "png" = "png",
  "pokeball" = "pokeball",
  "polaroids" = "polaroids",
  "poll" = "poll",
  "polywork" = "polywork",
  "poo" = "poo",
  "popcorn" = "popcorn",
  "portrait" = "portrait",
  "pot-2" = "pot-2",
  "pot" = "pot",
  "power-plant" = "power-plant",
  "power" = "power",
  "pram" = "pram",
  "presentation" = "presentation",
  "press" = "press",
  "print" = "print",
  "projector" = "projector",
  "puzzle" = "puzzle",
  "pyramid" = "pyramid",
  "qr-code-2" = "qr-code-2",
  "qr-code" = "qr-code",
  "question-mark-circle" = "question-mark-circle",
  "question-mark" = "question-mark",
  "quote" = "quote",
  "radio" = "radio",
  "rain" = "rain",
  "razor" = "razor",
  "receipt" = "receipt",
  "receive-money" = "receive-money",
  "reddit" = "reddit",
  "redo" = "redo",
  "refresh-2" = "refresh-2",
  "refresh-3" = "refresh-3",
  "refresh" = "refresh",
  "repeat" = "repeat",
  "reply" = "reply",
  "reports-chart" = "reports-chart",
  "rewind" = "rewind",
  "robot" = "robot",
  "rocket" = "rocket",
  "rollercoaster" = "rollercoaster",
  "rotate-2" = "rotate-2",
  "rotate" = "rotate",
  "rounded-corners-bl" = "rounded-corners-bl",
  "rounded-corners-br" = "rounded-corners-br",
  "rounded-corners-tl" = "rounded-corners-tl",
  "rounded-corners-tr" = "rounded-corners-tr",
  "route" = "route",
  "router" = "router",
  "rows-2" = "rows-2",
  "rows" = "rows",
  "rss" = "rss",
  "rucksack" = "rucksack",
  "ruler" = "ruler",
  "rupee" = "rupee",
  "safe" = "safe",
  "safety-pin" = "safety-pin",
  "sagittarius" = "sagittarius",
  "salesforce" = "salesforce",
  "salt" = "salt",
  "satellite" = "satellite",
  "scale-tool" = "scale-tool",
  "scale" = "scale",
  "scales" = "scales",
  "scan-minus" = "scan-minus",
  "scan-plus" = "scan-plus",
  "scissors" = "scissors",
  "scooter" = "scooter",
  "screw" = "screw",
  "screwdriver" = "screwdriver",
  "scroll" = "scroll",
  "scrubber" = "scrubber",
  "search-area" = "search-area",
  "search-field" = "search-field",
  "search-globe" = "search-globe",
  "search" = "search",
  "seedlings" = "seedlings",
  "seeds" = "seeds",
  "send-money" = "send-money",
  "send-to-back-2" = "send-to-back-2",
  "send-to-back" = "send-to-back",
  "send-to-front" = "send-to-front",
  "send" = "send",
  "server" = "server",
  "service-bell" = "service-bell",
  "settings-2" = "settings-2",
  "settings-sliders" = "settings-sliders",
  "settings" = "settings",
  "sewer" = "sewer",
  "shape-rotate" = "shape-rotate",
  "shape" = "shape",
  "share-2" = "share-2",
  "share-3" = "share-3",
  "share" = "share",
  "shield-cross" = "shield-cross",
  "shield-lock" = "shield-lock",
  "shield-tick" = "shield-tick",
  "shield-unlock" = "shield-unlock",
  "shield-warning" = "shield-warning",
  "shield" = "shield",
  "shipment-check" = "shipment-check",
  "shipment-cross" = "shipment-cross",
  "shipment" = "shipment",
  "shop-sign" = "shop-sign",
  "shopping-bag" = "shopping-bag",
  "shopping-basket-check" = "shopping-basket-check",
  "shopping-basket-cross" = "shopping-basket-cross",
  "shopping-basket" = "shopping-basket",
  "shopping-cart" = "shopping-cart",
  "shower-2" = "shower-2",
  "shower" = "shower",
  "shuffle" = "shuffle",
  "side-profile" = "side-profile",
  "sidebar" = "sidebar",
  "sign" = "sign",
  "signage" = "signage",
  "single-bed" = "single-bed",
  "skateboard" = "skateboard",
  "sketch" = "sketch",
  "skew" = "skew",
  "ski-mask" = "ski-mask",
  "skip-back" = "skip-back",
  "skip-forward" = "skip-forward",
  "skull" = "skull",
  "slash" = "slash",
  "slice" = "slice",
  "slide-menu" = "slide-menu",
  "sliders-horizontal" = "sliders-horizontal",
  "sliders-vertical" = "sliders-vertical",
  "slideshow" = "slideshow",
  "smartphone" = "smartphone",
  "snail" = "snail",
  "snapchat" = "snapchat",
  "snorkel" = "snorkel",
  "snow-mobile" = "snow-mobile",
  "soap-pump" = "soap-pump",
  "soccer-pitch" = "soccer-pitch",
  "solar-panels" = "solar-panels",
  "sombrero" = "sombrero",
  "sort-ascending" = "sort-ascending",
  "sort-descending" = "sort-descending",
  "sos" = "sos",
  "soundcloud" = "soundcloud",
  "south" = "south",
  "spacing-horizontal" = "spacing-horizontal",
  "spades" = "spades",
  "sparkles-2" = "sparkles-2",
  "sparkles" = "sparkles",
  "speaker-2" = "speaker-2",
  "speaker-mute-2" = "speaker-mute-2",
  "speaker-mute" = "speaker-mute",
  "speaker-off" = "speaker-off",
  "speaker-volume-high" = "speaker-volume-high",
  "speaker-volume-low" = "speaker-volume-low",
  "speaker" = "speaker",
  "spectacles-2" = "spectacles-2",
  "spectacles" = "spectacles",
  "speech-bubble-minus" = "speech-bubble-minus",
  "speech-bubble-plus" = "speech-bubble-plus",
  "speech-bubble" = "speech-bubble",
  "speech-important" = "speech-important",
  "speed-fast" = "speed-fast",
  "speed-gauge" = "speed-gauge",
  "speed-slow" = "speed-slow",
  "sphere" = "sphere",
  "spider" = "spider",
  "spinner" = "spinner",
  "spoon-2" = "spoon-2",
  "spoon" = "spoon",
  "spotify" = "spotify",
  "spray-paint" = "spray-paint",
  "spreadsheet" = "spreadsheet",
  "square-check" = "square-check",
  "square-cross" = "square-cross",
  "square-frame" = "square-frame",
  "square-interface" = "square-interface",
  "square-intersect" = "square-intersect",
  "square-minus" = "square-minus",
  "square-plus" = "square-plus",
  "square" = "square",
  "stack-overflow" = "stack-overflow",
  "stairs" = "stairs",
  "stamp" = "stamp",
  "star" = "star",
  "steering-wheel" = "steering-wheel",
  "stethoscope" = "stethoscope",
  "sticker" = "sticker",
  "sticky-note" = "sticky-note",
  "stiletto" = "stiletto",
  "stop" = "stop",
  "stream-to-tv-2" = "stream-to-tv-2",
  "stream-to-tv" = "stream-to-tv",
  "strikethrough" = "strikethrough",
  "subscription" = "subscription",
  "substitute" = "substitute",
  "sun" = "sun",
  "sunrise-2" = "sunrise-2",
  "sunrise" = "sunrise",
  "swap" = "swap",
  "swiss-army-knife" = "swiss-army-knife",
  "switch-2" = "switch-2",
  "switch" = "switch",
  "t-shirt" = "t-shirt",
  "table-columns" = "table-columns",
  "table-rows" = "table-rows",
  "tablet-drawing" = "tablet-drawing",
  "tablet" = "tablet",
  "tag-2" = "tag-2",
  "tag-add" = "tag-add",
  "tag" = "tag",
  "tap-left" = "tap-left",
  "tap-right" = "tap-right",
  "target" = "target",
  "taurus" = "taurus",
  "telegram" = "telegram",
  "telescope" = "telescope",
  "temperature-high" = "temperature-high",
  "temperature-low" = "temperature-low",
  "temperature" = "temperature",
  "tepee" = "tepee",
  "text-align-center" = "text-align-center",
  "text-align-justified" = "text-align-justified",
  "text-align-left" = "text-align-left",
  "text-align-right" = "text-align-right",
  "text-capitalise" = "text-capitalise",
  "text-lowercase" = "text-lowercase",
  "text-uppercase" = "text-uppercase",
  "text" = "text",
  "thumbs-down" = "thumbs-down",
  "thumbs-up" = "thumbs-up",
  "ticket" = "ticket",
  "tickets" = "tickets",
  "tiktok" = "tiktok",
  "timer-2" = "timer-2",
  "timer-3" = "timer-3",
  "timer-4" = "timer-4",
  "timer-checkmark" = "timer-checkmark",
  "timer-snooze" = "timer-snooze",
  "timer" = "timer",
  "toggle-left" = "toggle-left",
  "toggle-right" = "toggle-right",
  "toilet-roll" = "toilet-roll",
  "tomahawk" = "tomahawk",
  "toolbox" = "toolbox",
  "tooth-pain" = "tooth-pain",
  "tooth" = "tooth",
  "top-hat" = "top-hat",
  "toys" = "toys",
  "traffic-lights" = "traffic-lights",
  "trailer" = "trailer",
  "train-arriving" = "train-arriving",
  "train-departing" = "train-departing",
  "train" = "train",
  "trampoline" = "trampoline",
  "trash-2" = "trash-2",
  "trash-can" = "trash-can",
  "trash" = "trash",
  "tree-2" = "tree-2",
  "tree" = "tree",
  "trees" = "trees",
  "trending-down" = "trending-down",
  "trending-up" = "trending-up",
  "triangle-circle" = "triangle-circle",
  "triangle-ruler" = "triangle-ruler",
  "triangle" = "triangle",
  "trolly" = "trolly",
  "trophy" = "trophy",
  "trousers" = "trousers",
  "truck-2" = "truck-2",
  "truck" = "truck",
  "turn-volume-high" = "turn-volume-high",
  "turn-volume-low" = "turn-volume-low",
  "turntable" = "turntable",
  "twitch" = "twitch",
  "twitter" = "twitter",
  "two-checkmarks" = "two-checkmarks",
  "two-fingers" = "two-fingers",
  "two-hearts" = "two-hearts",
  "uae-dirham" = "uae-dirham",
  "ufo-2" = "ufo-2",
  "ufo" = "ufo",
  "ui-bottom" = "ui-bottom",
  "umbrella-2" = "umbrella-2",
  "umbrella" = "umbrella",
  "underline" = "underline",
  "undo" = "undo",
  "union-mask" = "union-mask",
  "union" = "union",
  "unordered-list" = "unordered-list",
  "unpin" = "unpin",
  "unread-message" = "unread-message",
  "upload-cloud" = "upload-cloud",
  "upload" = "upload",
  "upstairs" = "upstairs",
  "usb" = "usb",
  "user-check" = "user-check",
  "user-circle" = "user-circle",
  "user-cross" = "user-cross",
  "user-minus" = "user-minus",
  "user-plus" = "user-plus",
  "user" = "user",
  "users-check" = "users-check",
  "users-cross" = "users-cross",
  "users-minus" = "users-minus",
  "users-plus" = "users-plus",
  "users" = "users",
  "vaccine" = "vaccine",
  "vanity" = "vanity",
  "vase" = "vase",
  "verified" = "verified",
  "vga-cable" = "vga-cable",
  "vice-grip" = "vice-grip",
  "video-camera-2" = "video-camera-2",
  "video-camera-off-2" = "video-camera-off-2",
  "video-camera-off" = "video-camera-off",
  "video-camera" = "video-camera",
  "video" = "video",
  "virgo" = "virgo",
  "virus" = "virus",
  "voice-id-2" = "voice-id-2",
  "voice-id" = "voice-id",
  "voicemail" = "voicemail",
  "volcano" = "volcano",
  "vpn" = "vpn",
  "vr" = "vr",
  "wall-socket-uk" = "wall-socket-uk",
  "wall-socket-usa" = "wall-socket-usa",
  "wallet" = "wallet",
  "wallpaper" = "wallpaper",
  "wand" = "wand",
  "warning-triangle" = "warning-triangle",
  "washer" = "washer",
  "waves" = "waves",
  "weed" = "weed",
  "west" = "west",
  "wheat" = "wheat",
  "wheel" = "wheel",
  "width" = "width",
  "wifi-high" = "wifi-high",
  "wifi-low" = "wifi-low",
  "wifi-medium" = "wifi-medium",
  "wifi-no-connection" = "wifi-no-connection",
  "wifi" = "wifi",
  "wind-power" = "wind-power",
  "wind" = "wind",
  "windows" = "windows",
  "wine" = "wine",
  "wink" = "wink",
  "wish-list" = "wish-list",
  "world" = "world",
  "wrench-2" = "wrench-2",
  "wrench" = "wrench",
  "write-note" = "write-note",
  "x-axis" = "x-axis",
  "xls" = "xls",
  "y-axis" = "y-axis",
  "yen" = "yen",
  "yin-yang" = "yin-yang",
  "zip-file-2" = "zip-file-2",
  "zip-file" = "zip-file",
  "zoom-in" = "zoom-in",
  "zoom-out" = "zoom-out"
}