import React, { FC, useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { useNinetailed } from "@ninetailed/experience.js-gatsby"

import {
  convertStringToKebabCase,
  generateConditionalClassName,
  isClientSide,
  isTouchDevice as isTouchDeviceUtil
} from "@utils"
import {
  BREAKPOINTS,
  LOCAL_STORAGE_NAME_LOGGED_IN,
} from "@constants"

import Button from "@components/Button/Button"
import Icon, { ICON_TYPES } from "@components/Icon/Icon"

import SiteNavDrawer from "./SiteNavDrawer/SiteNavDrawer"

import { getJsonData, useScrolledAndVisible } from "./SiteNav.hooks"
import { SiteNavHEADER, SiteNavModalOverlayBUTTON, SiteNavNAV } from "./SiteNav.styles"
import SiteNavLogo from "./SiteNavLogo/SiteNavLogo"
import useCommonUrlsAndNumbers from "@hooks/useCommonUrlsAndNumbers"

type SiteNavProps = {
  isStatic?: boolean
  includeLoginButton?: boolean
}

const SITE_NAV_CLOSE_DELAY = 100

export const SITE_NAV_HEIGHT = ""

const SiteNav: FC<SiteNavProps> = ({
  isStatic,
  includeLoginButton,
}) => {
  const {
    audience,
    experience,
    hasExperience,
    siteNavData,
    variantIndex,
  } = getJsonData()

  const [isMobile, setIsMobile] = useState<boolean>(true)
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [messagesBannerOffset, setMessagesBannerOffset] = useState<string>("0px")
  const scrolledAndVisibleState = useScrolledAndVisible(isOpen)
  const [activeDrawerId, setActiveDrawerId] = useState<string | null | undefined>(null)
  const closeTimeoutRef = useRef<NodeJS.Timeout | number | null>(null)
  const headerRef = useRef<HTMLElement>(null)
  const mutationRef = useRef<MutationObserver | null>(null)
  const navItems = (siteNavData?.json?.navItems || []).filter(navItem => !!navItem)

  const {
    demoUrl,
    signupPlusUrl,
  } = useCommonUrlsAndNumbers()

  const navItemsIds = navItems.map((navItem) => navItem?.id)

  const {
    observeElement,
    unobserveElement,
  } = useNinetailed()

  useEffect(() => {
    if (!isClientSide() || !headerRef.current || !experience) return

    observeElement({
      audience,
      element: headerRef.current,
      experience,
      variant: siteNavData,
      variantIndex,
    })

    return () => {
      if (headerRef.current) unobserveElement(headerRef.current)
    }
  }, [headerRef.current, experience])

  useEffect(() => {
    if (!isClientSide()) return

    // Hacky code to listen for a HS Messages Banner to be added to the page :/
    const handleMutations: MutationCallback = () => {
      if (document.body.style.marginTop) {
        setMessagesBannerOffset(document.body.style.marginTop)
      } else {
        setMessagesBannerOffset("0px")
      }
    }
    mutationRef.current = new MutationObserver(handleMutations)
    mutationRef.current.observe(document.body, {
      attributes: true,
      attributeFilter: ["style"],
    })
    // End hacky code!

    const updateIsMobile = () => setIsMobile(window.innerWidth < BREAKPOINTS.L)

    window.addEventListener("resize", updateIsMobile)

    updateIsMobile()

    setIsTouchDevice(isTouchDeviceUtil())

    const handleClick = (e: MouseEvent) => {
      if (e.target?.closest("a")) {
        setActiveDrawerId(null)
        setIsOpen(false)
      }
    }

    if (headerRef.current) {
      headerRef.current.addEventListener("click", handleClick)
    }

    return () => {
      if (mutationRef.current) {
        mutationRef.current.disconnect()
      }

      window.removeEventListener("resize", updateIsMobile)

      if (headerRef.current) {
        headerRef.current.removeEventListener("click", handleClick)
      }
    }
  }, [])

  useEffect(() => {
    if (!isClientSide()) return

    document.body.style.overflowY = (isMobile && isOpen) ? "hidden" : "auto"

    if (!isMobile && isTouchDevice && isOpen) {
      setActiveDrawerId(navItemsIds[0])
      return
    }

    if (isOpen) return

    setActiveDrawerId(null)
  }, [isOpen, isMobile, isTouchDevice])

  const handleMouseLeave = () => {
    if (isTouchDevice || isMobile) return
    if (closeTimeoutRef.current) clearTimeout(closeTimeoutRef.current)
    closeTimeoutRef.current = setTimeout(() => {
      setActiveDrawerId(null)
      setIsOpen(false)
    }, SITE_NAV_CLOSE_DELAY)
  }

  const handleMouseOverNavItem = (navItemId: string) => {
    if (isTouchDevice || isMobile) return
    if (closeTimeoutRef.current) clearTimeout(closeTimeoutRef.current)
    if (!isMobile && activeDrawerId !== navItemId) {
      setActiveDrawerId(navItemId)
      setIsOpen(true)
    }
  }

  const handleClickLogin = () => {
    window.localStorage.setItem(LOCAL_STORAGE_NAME_LOGGED_IN, "true")
  }

  return (
    <>
      <SiteNavModalOverlayBUTTON
        aria-label="Close Navigation"
        tabIndex={-1}
        className={generateConditionalClassName({
          "modal-overlay": true,
          "is-visible": isOpen,
        })}
        onClick={() => setIsOpen(false)}
      />
      <SiteNavHEADER
        className={generateConditionalClassName({
          SiteNav: true,
          "is-open": isOpen,
          [`scrolled--${scrolledAndVisibleState}`]: !isStatic && !!scrolledAndVisibleState,
          "has-experience": !!hasExperience,
        })}
        onMouseLeave={handleMouseLeave}
        ref={headerRef}
        style={{ [`--sitenav-messages-banner-offset`]: messagesBannerOffset }}
      >
        <SiteNavNAV>
          <div className="container">
            <ul className="mobile-nav flex">
              <li className="header-logo">
                <Link to="/" aria-label="Return to home page">
                  <SiteNavLogo />
                </Link>
              </li>
              <li className="touch-menu-toggle">
                <button
                  aria-label={isOpen ? "Close Menu" : "Open Menu"}
                  onClick={() => setIsOpen(_isOpen => !_isOpen)}
                >
                  <Icon
                    size="24px"
                    type={ICON_TYPES["close"]}
                  />
                  <Icon
                    size="24px"
                    type={ICON_TYPES["menu"]}
                  />
                </button>
              </li>
            </ul>
            <ul className="main-nav flex">
              <li className="header-logo">
                <Link to="/" aria-label="Return to home page">
                  <SiteNavLogo />
                </Link>
              </li>
              {navItems.map((navItem) => !navItem ? null : (
                navItem.url ? (
                  <li key={navItem.id}>
                    <Link
                      className="nav-link"
                      key={navItem.id}
                      to={navItem.url}
                    >
                      {navItem.title}
                    </Link>
                  </li>
                ) : (
                  <li
                    className={generateConditionalClassName({
                      "nav-item": true,
                      [convertStringToKebabCase(navItem.title || "")]: true,
                    })}
                    key={navItem.id}
                  >
                    <button
                      className={generateConditionalClassName({
                        "nav-link": true,
                        "is-active": activeDrawerId === navItem.id,
                      })}
                      key={navItem.id}
                      onMouseOver={() => handleMouseOverNavItem(navItem.id || "")}
                      onClick={() => {
                        if (activeDrawerId === navItem.id && isMobile) {
                          setActiveDrawerId(null)
                          return
                        }
                        setActiveDrawerId(navItem.id)
                        setIsOpen(true)
                      }}
                      tabIndex={isOpen ? (navItemsIds.indexOf(navItem.id) + 1) * 2 : undefined}
                    >
                      {navItem.title}
                      <Icon className="chevron" type={ICON_TYPES["chevron-down"]} />
                    </button>
                    {navItem.columns && (
                      <SiteNavDrawer
                        columns={navItem.columns}
                        id={navItem.id || ""}
                        isActive={activeDrawerId === navItem.id}
                        isMobile
                        key={`${navItem.id}-drawer`}
                        tabIndex={isMobile && activeDrawerId === navItem.id ? 0 : -1}
                      />
                    )}
                  </li>
                ))
              )}
              <li className="get-a-demo">
                {includeLoginButton && (
                  <a
                    className="nav-link"
                    href="https://secure.helpscout.net/"
                    onClick={handleClickLogin}
                  >
                    Login
                  </a>
                )}
                {!includeLoginButton && (
                  <Link
                    className="nav-link get-a-demo"
                    to={demoUrl || "/"}
                  >
                    Get a Demo
                  </Link>
                )}
              </li>
              <li className="free-trial">
                <Button href={signupPlusUrl || "/"}>Sign Up Free</Button>
              </li>
              <li className="touch-menu-toggle">
                <button
                  aria-label={isOpen ? "Close Menu" : "Open Menu"}
                  onClick={() => { setIsOpen(_isOpen => !_isOpen) }}
                >
                  <Icon
                    size="24px"
                    type={ICON_TYPES["close"]}
                  />
                  <Icon
                    size="24px"
                    type={ICON_TYPES["menu"]}
                  />
                </button>
              </li>
            </ul>
            <div className="desktop-drawers">
              {navItems.map(navItem => navItem && navItem.columns && (
                <div
                  className={convertStringToKebabCase(navItem.title || "")}
                  key={navItem.id}
                >
                  <SiteNavDrawer
                    key={`${navItem.id}-drawer`}
                    id={navItem.id || ""}
                    isActive={activeDrawerId === navItem.id}
                    columns={navItem.columns}
                    tabIndex={isOpen && !isMobile && activeDrawerId === navItem.id ? (navItemsIds.indexOf(navItem.id) + 1) * 2 + 1 : -1}
                  />
                </div>
              ))}
            </div>
          </div>
        </SiteNavNAV>
      </SiteNavHEADER>
    </>
  )
}

export default SiteNav
