/*
  Cookie Utilities
*/
export const getCookie = (cookieName: string): string | null => {
  const cookies = document.cookie.split("; ")
  const match = cookies.find(cookie => cookie.includes(`${cookieName}=`))
  return match ? match.split("=")[1] : null
}

export const setCookie = (cookieName: string, value: string): void => {
  const today = new Date()
  const expirationDate = new Date(today)
  // 365 days out
  expirationDate.setDate(today.getDate() + 365)
  document.cookie = cookieName + "=" + value + ";path=/;expires=" + expirationDate.toUTCString()
}


