// Breakpoints
import {
  BREAKPOINTS,
  MEDIA_QUERIES,
} from "./_breakpoints"

// Hubspot Form IDs
import { FORM_IDS, FORM_IDS_KEYS } from "./_formIds"

// Key Codes
import { KEYS } from "./_keyCodes"

export {
  BREAKPOINTS,
  FORM_IDS,
  FORM_IDS_KEYS,
  KEYS,
  MEDIA_QUERIES,
}

// Miscellaneous

export const CONTENT_MAX_WIDTH: number = 908;

export const COOKIE_NAME_NEWSLETTER_NOTHANKS: string = 'newsletter_nothanks';
export const COOKIE_NAME_NEWSLETTER_SUBSCRIBER: string = 'newsletter_subscriber';

export const LOCAL_STORAGE_KEY_NEWSLETTER_SUBSCRIBER: string = COOKIE_NAME_NEWSLETTER_SUBSCRIBER;
export const LOCAL_STORAGE_KEY_NEWSLETTER_NOTHANKS: string = COOKIE_NAME_NEWSLETTER_NOTHANKS;
export const LOCAL_STORAGE_NAME_LOGGED_IN: string = 'HS--log-in-clicked';

export const NINETAILED_CBP_AB_EXPERIENCE_ID = "OiylN10l5R4rUKvCJTyua"
