import { Link } from "gatsby"
import React, { FC } from "react"
import styled from "styled-components"

type SiteNavDrawerFooterSmallListProps = {
  tabIndex?: number,
  items?: {
    title?: string | null,
    url?: string | null,
  }[] | null
}

const SiteNavDrawerFooterSmallListDIV = styled.div`
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: var(--border-radius--S) var(--spacing--base-0-5);
  margin-top: calc(var(--spacing--base-1-5) - var(--SiteNav--drawer-column--gap-y));

  a {
    color: var(--color--text-light);
    flex: 0 0 calc(50% - var(--spacing--base-0-5) / 2);
    font-size: var(--font-size--XS);
  }
`

const SiteNavDrawerFooterSmallList: FC<SiteNavDrawerFooterSmallListProps> = ({
  items,
  tabIndex,
}) => !items ? null : (
  <SiteNavDrawerFooterSmallListDIV>
    {items.map(item => (!item.title || !item.url) ? null : (
      <Link
        key={item.url}
        tabIndex={tabIndex}
        to={item.url}
      >
        {item.title}
      </Link>
    ))}
  </SiteNavDrawerFooterSmallListDIV>
)

export default SiteNavDrawerFooterSmallList
