import { Script } from "gatsby"
import React from "react"

const WEBSITE_SCHEMA = JSON.stringify({
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "Help Scout",
  "url": "https://www.helpscout.com/"
})

const CORPORATION_SCHEMA = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "Corporation",
  "name": "Help Scout",
  "alternateName": "HelpScout",
  "url": "https://www.helpscout.com/",
  "logo": `https://www.helpscout.com/images/favicon/android-chrome-512x512.png`,
  "sameAs": [
    "https://www.facebook.com/helpscout/",
    "https://twitter.com/helpscout",
    "https://www.instagram.com/hellohelpscout/",
    "https://www.youtube.com/@HelpscoutNet",
    "https://www.linkedin.com/company/help-scout",
    "https://en.wikipedia.org/wiki/Help_Scout",
    "https://www.helpscout.com/"
  ]
})

const StructuredData = () => (
  <>
    <Script
      id="website-schema-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: WEBSITE_SCHEMA }}
    />
    <Script
      id="corporation-schema-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: CORPORATION_SCHEMA }}
    />
  </>
)

export default StructuredData
