import styled from "styled-components"

import { MEDIA_QUERIES } from "@constants"

import { FIXED_CTA_BAR_NOISE_BG } from "./FixedCtaBar.constants"

export const FixedCtaBarDIV = styled.div`
  --FixedCtaBar--height: 60px;
  --FixedCtaBar--padding: 4px;
  --FixedCtaBar--offset-bottom: calc(var(--hs-beacon-v-offset, 40px) - var(--FixedCtaBar--padding));

  backdrop-filter: blur(2px);
  border-radius: calc(var(--border-radius--2XL) + var(--FixedCtaBar--padding));
  bottom: var(--FixedCtaBar--offset-bottom);
  left: 50%;
  opacity: 0;
  padding: var(--FixedCtaBar--padding);
  position: fixed;
  transition: translate 0.3s, opacity 0.3s;
  translate: -50% calc(var(--FixedCtaBar--offset-bottom) + 100%);
  z-index: var(--SiteNav--z-index);
  width: calc(var(--container-width) + var(--FixedCtaBar--padding) * 2);

  &.is-visible {
    opacity: 1;
    translate: -50% 0;
  }

  ${MEDIA_QUERIES.S} {
    left: calc((100vw - var(--container-width)) / 2);
    translate: 0 calc(var(--FixedCtaBar--offset-bottom) + 100%);
    max-width: calc(var(--container-width) + var(--FixedCtaBar--padding) * 2 - 140px);

    &.is-visible {
      translate: 0 0;
    }
  }

  ${MEDIA_QUERIES.M} {
    left: 50%;
    height: calc(var(--FixedCtaBar--height) + var(--FixedCtaBar--padding) * 2);
    max-width: none;
    translate: -50% calc(var(--FixedCtaBar--offset-bottom) + 100%);
    width: auto;

    &.is-visible {
      translate: -50% 0;
    }
  }

  .gradient-border {
    background: linear-gradient(235deg, #FFA977 0%,#FF85DA 33.3333%, #7D77FF 66.6666%, #68ECE6 100%);
    border-radius: calc(var(--border-radius--2XL) + var(--FixedCtaBar--padding));
    box-shadow: var(--elevation--M);
    height: 100%;
    left: 0;
    opacity: 0.48;
    position: absolute;
    top: 0;
    width: 100%;

    ${MEDIA_QUERIES.M} {
      border-radius: 100000px;
    }
  }

  .button {
    position: relative;

    &::after {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0)), var(--color--action-default);
      border-radius: var(--border-radius--S);
      content: "";
      display: block;
      height: calc(100% + 2px);
      left: -1px;
      position: absolute;
      top: -1px;
      transition: background 0.3s;
      width: calc(100% + 2px);
      z-index: -1;
    }

    &:hover::after {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0)), var(--color--action-hovered);
    }
  }

  .close-button {
    opacity: 0.64;
    padding: 4px;
    margin: 0 -4px 0 calc(var(--spacing--base-0-5) - var(--spacing--XS));
    position: absolute;
    top: 8px;
    right: 8px;

    svg {
      vertical-align: top;
    }

    &:hover {
      opacity: 0.8;
    }

    ${MEDIA_QUERIES.M} {
      position: static;
    }
  }

  .content {
    align-items: center;
    backdrop-filter: blur(8px);
    background:
      url("${FIXED_CTA_BAR_NOISE_BG}"),
      rgba(var(--color--charcoal-1200-rgb), 0.8);
    border-radius: var(--border-radius--2XL);
    display: flex;
    flex-direction: column;
    gap: var(--spacing--base-0-75);
    padding: var(--spacing--base-2-5);
    position: relative;
    z-index: 1;
    box-shadow:
      0px 4px 4px 1px rgba(var(--color--charcoal-900-rgb), 0.06),
      0px 2px 2px 0.5px rgba(var(--color--charcoal-900-rgb), 0.04),
      0px 1px 1px 0px rgba(var(--color--charcoal-900-rgb), 0.02),
      inset 0 0 0 1px rgba(255, 255, 255, 0.24);

    p {
      color: var(--color--basics-white);
      margin: 0 0 calc(var(--spacing--S) - var(--spacing--base-0-75));
      text-align: center;
      text-wrap: balance;
    }

    .button {
      width: auto;

      ${MEDIA_QUERIES.S} {
        width: 100%;
      }

      &.desktop {
        display: none;
      }
    }

    ${MEDIA_QUERIES.M} {
      border-radius: 100000px;
      flex-direction: row;
      gap: var(--spacing--XS);
      height: var(--FixedCtaBar--height);
      padding: 0 calc(var(--spacing--base-1-25) - var(--FixedCtaBar--padding)) 0 calc(var(--spacing--base-1-75) - var(--FixedCtaBar--padding));

      p {
        margin: 0;
        white-space: nowrap;
      }

      .button {
        width: auto;

        &.desktop {
          display: block;
        }

        &.mobile {
          display: none;
        }
      }
    }

    ${MEDIA_QUERIES.XL} {
      p {
        font-size: 1.125rem; // prevent 2x font-size
      }

      .button {
        font-size: 0.875rem; // prevent 2x font-size
      }
    }
  }

  &.is-trial {
    .gatsby-image-wrapper {
      position: relative;
      top: 2px;
    }
  }
`
