import { useEffect, useRef } from "react"

import { getCookie, isClientSide, setCookie } from "@utils"

const PartnerStackCookies = () => {
  const TimeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (!isClientSide()) return

    // Manually store the `gspk` cookie since the PartnerStack script
    // is not doing this
    const gspkMatches = window.location.search.match(/gspk=(.+?)(?:&|$)/)

    if (gspkMatches) setCookie("gspk", gspkMatches[1])

    // In addition to the cookies and parameters sent to helpscount.net
    // from attribution.js, we need to check for cookies from PartnerStack
    // and also pass those along in the queryString of any helpscout.net links.
    const helpScoutNetLinks = Array.from(document.querySelectorAll<HTMLAnchorElement>(`a[href*="helpscout.net"]`))

    if (!helpScoutNetLinks.length) return

    const partnerStackCookiesIds = ["gspk", "gsxid"]
    const partnerStackCookieParams: { [k: string]: string } = partnerStackCookiesIds
      .reduce((acc, id) => {
        const cookie = getCookie(id)
        if (!cookie) return acc
        return {
          ...acc,
          [id]: cookie,
        }
      }, {})

    if (!Object.keys(partnerStackCookieParams).length) return

    const appendPartnerStackParamsToLinks = () => {
      helpScoutNetLinks.forEach(link => {
        const urlAndQueryString = link.href.split("?")
        const existingParams: { [k: string]: string } = (urlAndQueryString[1] || "").split("&").reduce((acc, param) => {
          if (!param) return acc

          const splitParam = param.split("=")
          return {
            ...acc,
            [splitParam[0]]: splitParam[1],
          }
        }, {})

        const params = {
          ...existingParams,
          ...partnerStackCookieParams,
        }

        const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join("&")

        link.href = `${urlAndQueryString[0]}?${queryString}`
      })
    }

    // Short setTimeout to allow attribution.js to do its thing
    TimeoutRef.current = setTimeout(appendPartnerStackParamsToLinks, 100)

    return () => {
      if (TimeoutRef.current) clearTimeout(TimeoutRef.current)
    }
  }, [])

  return null
}

export default PartnerStackCookies
