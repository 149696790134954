import { isClientSide } from "@utils"
import React, { FC, useEffect, useState } from "react"

type HiddenFieldProps = {
  defaultValue?: string | null
  defaultValues?: (string | null)[] | null
  id: string
  name: string
}

const HiddenField: FC<HiddenFieldProps> = ({
  defaultValue,
  defaultValues,
  id,
  name,
}) => {
  const [value, setValue] = useState<string>(
    defaultValues ? defaultValues.join(";") : (defaultValue || "")
  )

  useEffect(() => {
    if (!isClientSide()) return

    const queryStringValue = (new window.URLSearchParams(window.location.search.replace("?", ""))).get(name)

    if (queryStringValue) setValue(queryStringValue)
  }, [])
  return (
    <input
      key={id}
      id={id}
      onChange={e => { setValue(e.target.value) }}
      type="text"
      name={name || ""}
      value={value}
    />
  )
}

export default HiddenField
