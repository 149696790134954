import { BREAKPOINTS } from '@constants'
import { generateFluidCalc } from '@utils'
import styled from 'styled-components';


// import { HeaderLogoLINK } from '../SiteHeader/HeaderLogo/HeaderLogo';

export const SiteFooterSmallFOOTER = styled.footer`
  margin-top: auto;
  border-top: 1px solid var(--color--charcoal-200);
  background: var(--color--basics-white);
`;

export const SiteFooterSmallColumnsDIV = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing--base-1-5) 0;

  // Logo
  svg {
    width: ${generateFluidCalc(86, 128, BREAKPOINTS.S, BREAKPOINTS.XL)};
    vertical-align: bottom;
  }
`;
