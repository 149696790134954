import Icon, { ICON_TYPES } from "@components/Icon/Icon"
import React, { FC } from "react"
import { Tooltip } from "react-tooltip"
import styled from "styled-components"

type ValidationIconProps = {
  message?: string,
  fieldName: string,
  size?: string,
}

const ValidationIconDIV = styled.div`
  position: absolute;
  right: var(--input--padding-x-L);
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  .react-tooltip.react-tooltip {
    min-width: 0;
  }
`

const ValidationIcon: FC<ValidationIconProps> = ({
  fieldName,
  message,
  size,
}) => {
  const id = `Tooltip--${fieldName}`
  return (
    <ValidationIconDIV className="validation-icon">
      <span data-tooltip-id={id}>
        <Icon
          color="var(--color--error-border)"
          size={size || "calc(var(--font-size--M) * var(--line-height--relaxed))"}
          type={ICON_TYPES["warning-triangle"]}
        />
      </span>
      <Tooltip
        className="font-family--sans font-size--S"
        id={id}
        offset={3}
        place="top-end"
      >
        {message || "Sorry, that is required"}
      </Tooltip>
    </ValidationIconDIV>
  )
}

export default ValidationIcon
