import React, { FC, MouseEventHandler, ReactElement } from "react"

import { generateConditionalClassName, processShortcodes } from "@utils"
import { Link } from "gatsby"

export enum BUTTON_SIZES {
  DEFAULT = "M",
  S = "S",
  M = "M",
  L = "L",
}

export enum BUTTON_VARIANTS {
  OUTLINE = "OUTLINE", // Only to be used by IconButton!
  PRIMARY = "PRIMARY", // Default
  SECONDARY = "SECONDARY",
  WARNING = "WARNING"
}

export type ButtonProps = {
  children: ReactElement | string,
  className?: string,
  disabled?: boolean,
  href?: string,
  id?: string,
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>,
  size?: BUTTON_SIZES,
  target?: string,
  variant?: BUTTON_VARIANTS
}

const Button: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  href,
  id,
  onClick,
  size = BUTTON_SIZES.DEFAULT,
  target,
  variant = BUTTON_VARIANTS.PRIMARY,
  ...buttonProps
}) => {
  const _className = generateConditionalClassName({
    Button: true,
    button: true,
    [`${className || ""}`]: !!className,
    disabled: !!disabled,
    [`button--${size}`]: size !== BUTTON_SIZES.M,
    [`button--${variant.toLowerCase()}`]: variant !== BUTTON_VARIANTS.PRIMARY,
  })

  const _href = href ? processShortcodes(href) : ""

  if (!_href && onClick) {
    return (
      <button
        className={_className}
        disabled={!!disabled}
        id={id}
        onClick={onClick}
        {...buttonProps}
      >
        {children}
      </button>
    )
  }

  if (_href && !_href.includes("//") && !_href.includes(".pdf")) {
    return (
      <Link
        className={_className}
        id={id}
        onClick={onClick}
        target={target}
        to={_href}
        {...buttonProps}
      >
        {children}
      </Link>
    )
  }

  if (_href) {
    return (
      <a
        className={_className}
        href={_href}
        id={id}
        onClick={onClick}
        target={target}
        {...buttonProps}
      >
        {children}
      </a>
    )
  }

  return (
    <span
      className={_className}
      id={id}
      {...buttonProps}
    >
      {children}
    </span>
  )
}

export default Button
