/* 
  Local Storage Utilities 
*/

export const isLocalStorageKeySet = (key: string): boolean => {
  const item = localStorage.getItem(key);
  return item !== null;
};

export const getLocalStorageKey = (key: string): string | null => {
  const item = localStorage.getItem(key);
  if (item) {
    const data = JSON.parse(item);
    return data.value;
  }
  return null;
};

export const setLocalStorageKeyValue = (key: string, value: string): void => {
  const data = {
    value,
    expires: new Date().getTime() + 365 * 24 * 60 * 60 * 1000 // expires in 365 days
  };

  localStorage.setItem(key, JSON.stringify(data));
};