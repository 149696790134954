import React, { ReactElement } from "react"

const UNIFY_SCRIPT: string = `
!function(){window.unify||(window.unify=Object.assign([],["identify","page","startAutoPage","stopAutoPage","startAutoIdentify","stopAutoIdentify"].reduce((function(t,e){return t[e]=function(){return unify.push([e,[].slice.call(arguments)]),unify},t}),{})));var t=document.createElement("script");t.async=!0,t.setAttribute("src","https://tag.unifyintent.com/v1/nnStwLc4hpAqP3A3ZP4CY/script.js"),t.setAttribute("data-api-key","wk_L3PnT53J_EggwaMtf7a3BoR7Kafoaets3xdEKDcj5"),t.setAttribute("id","unifytag"),(document.body||document.head).appendChild(t)}();
`

const UnifyScript = (): ReactElement => (
  <script
    id="UnifyScript"
    dangerouslySetInnerHTML={{ __html: UNIFY_SCRIPT }}
  />
)

export default UnifyScript
