import styled, { css } from "styled-components"
import { Link } from "gatsby"

const TextLinkStyles = css`
  border: none;
  color: var(--color--action-default);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family--sans-brand);
  font-weight: var(--font-weight--semibold);
  position: relative;
  white-space: nowrap;

  &:hover {
    color: var(--color--action-hovered);
  }

  svg {
    height: 0.675em;
    width: auto;
    margin-left: 0.3333em;
    transform: translateY(0.02em);
  }

  &:focus-visible {
    background: var(--color--cobalt-100);
  }

  // useDarkMode
  &.use-dark-mode {
    color: var(--color--cobalt-400);

    &:hover,
    &:active {
      color: var(--color--cobalt-300);
    }

    &:focus-visible {
      color: var(--color--action-pressed);
    }
  }

  &.use-rotate-svg {
    svg {
      transform: translateY(0.03em) rotate(90deg);
    }
  }
`

export const TextLinkLINK = styled(Link)`
  && {
    ${TextLinkStyles}
  }
`

export const TextLinkA = styled.a`
  ${TextLinkStyles}
`

export const TextLinkSPAN = styled.span`
  ${TextLinkStyles}
`
