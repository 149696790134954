// See:
// https://github.com/Shopify/polaris/blob/master/src/types.ts

enum KEYS_KEYS {
  BACKSPACE = "BACKSPACE",
  TAB = "TAB",
  ENTER = "ENTER",
  SHIFT = "SHIFT",
  CTRL = "CTRL",
  ALT = "ALT",
  PAUSE = "PAUSE",
  CAPS_LOCK = "CAPS_LOCK",
  ESCAPE = "ESCAPE",
  SPACE = "SPACE",
  PAGE_UP = "PAGE_UP",
  PAGE_DOWN = "PAGE_DOWN",
  END = "END",
  HOME = "HOME",
  LEFT_ARROW = "LEFT_ARROW",
  UP_ARROW = "UP_ARROW",
  RIGHT_ARROW = "RIGHT_ARROW",
  DOWN_ARROW = "DOWN_ARROW",
  INSERT = "INSERT",
  DELETE = "DELETE",
  LEFT_META = "LEFT_META",
  RIGHT_META = "RIGHT_META",
  SELECT = "SELECT",
}

export const KEYS: { [k in KEYS_KEYS]: number } = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PAUSE: 19,
  CAPS_LOCK: 20,
  ESCAPE: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  INSERT: 45,
  DELETE: 46,
  LEFT_META: 91,
  RIGHT_META: 92,
  SELECT: 93,
}
