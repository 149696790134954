import styled from "styled-components"

import { generateFluidCalc } from "@utils"
import { BREAKPOINTS, MEDIA_QUERIES } from "@constants"

export const SiteFooterFOOTER = styled.footer`
  // Aspect Ratio
  --site-footer--big-logo--offset-right: calc((100vw  - var(--container-width)) / 2);
  --site-footer--big-logo--width: calc(100vw - var(--site-footer--big-logo--offset-right));
  --site-footer--big-logo--height: calc(var(--site-footer--big-logo--width) * 0.073532);

  position: relative;
  background: var(--color--charcoal-1200);
  padding: var(--spacing--L) 0 calc(var(--site-footer--big-logo--height) + var(--spacing--L));

  *::selection {
    background: var(--color--charcoal-900);
    color: var(--color--charcoal-200);
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .SiteFooter--big-logo {
    position: absolute;
    width: var(--site-footer--big-logo--width);
    bottom: 0;
    right: var(--site-footer--big-logo--offset-right);
  }

  ${MEDIA_QUERIES.L} {
    padding-bottom: calc(var(--site-footer--big-logo--height) + var(--spacing--M));
  }

  ${MEDIA_QUERIES.XL} {
    --site-footer--big-logo--offset-right: calc((100vw  - var(--container-width)) / 2 - 2.5vw);
    padding-bottom: var(--site-footer--big-logo--height);
  }

  .flex {
    gap: var(--spacing--L) var(--spacing--M);
  }

  .SiteFooter--column {
    flex: 0 0 calc(50% - var(--spacing--M) / 2);
    display: flex;
    flex-direction: column;
    gap: var(--spacing--base-1);

    ${MEDIA_QUERIES.M} {
      flex: 0 0 calc(33.3333% - var(--spacing--M) * 2 / 3);
    }

    div {
      margin: 0;
    }

    &.desktop-meta {
      display: none;
      margin-left: auto;
    }

    &.mobile-meta {
      display: flex;
      gap: var(--spacing--S) calc(var(--spacing--base-1-5) + 9px);

      &.social-links-copyright {
        flex-direction: column;
        justify-content: center;

        ${MEDIA_QUERIES.M} {
          justify-content: flex-start;
          padding-top: var(--spacing--XS);
        }
      }
    }

    ${MEDIA_QUERIES.L} {
      flex: 0 0 16.5%;
      gap: var(--spacing--base-0-5);

      &.compare {
        flex: 0 0 calc(16.5% * 0.8);
      }

      &.desktop-meta {
        flex: 0 0 auto;
        display: flex;
        gap: calc(var(--spacing--base-1-25) + 16px) 0; // 16px = invisible padding on B Corp graphic in Figma
      }

      &.mobile-meta {
        display: none;
      }
    }

    ${MEDIA_QUERIES.XL} {
      flex: 0 0 17.8%;

      &.compare {
        flex: 0 0 calc(17.8% * 0.8);
      }
    }

    h4 {
      color: var(--color--charcoal-300);
    }

    a,
    span:not(.Badge) {
      color: var(--color--charcoal-600);
      font-size: var(--font-size--S);
    }
    
    a {
      ${MEDIA_QUERIES.S} {
        white-space: nowrap;
      }

      > svg {
        vertical-align: bottom;
      }

      .Badge {
        position: relative;
        top: -0.18em;
        margin-left: 0.33em;

        &.status-badge {
          background-color: var(--color--charcoal-1000);
          color: var(--color--charcoal-400);
          font-family: var(--font-family--sans);
          font-weight: var(--font-weight--medium);
          letter-spacing: .08em;

          .Icon {
            position: relative;
            top: 0.19em;
            margin-left: -0.1em;
            margin-right: 0.1em;

            svg path {
              fill: var(--color--charcoal-400);
              stroke: transparent;
            }
          }
        }
      }

      .Icon {
        display: inline-block;
        position: relative;
        top: -0.2em;
        margin-left: 0.15em;

        svg path {
          stroke: var(--color--charcoal-600);
        }
      }

      &:hover,
      &:active {
        color: var(--color--charcoal-500);

        .Icon svg path {
          stroke: var(--color--charcoal-500);
        }
      }

      &:focus-visible {
        box-shadow: var(--focus-link--onCharcoal-1200);
      }
    }

    > a,
    > span {
      display: block;
    }
  }

  .social-links {
    display: flex;
    gap: 0 var(--spacing--XS);

    svg path {
      transition: fill 0.3s;
    }

    a:hover svg path {
      fill: var(--color--charcoal-500);
    }
  }

  .responsibility {
    display: flex;
    gap: 0 calc(var(--spacing--base-1-5) + 9px);
    align-items: center;

    ${MEDIA_QUERIES.L} {
      justify-content: space-between;
    }

    ${MEDIA_QUERIES.XL} {
      svg {
        &:first-child {
          width: ${generateFluidCalc(44, 55, BREAKPOINTS.XL, BREAKPOINTS["2XL"])};
        }
        &:last-child {
          width: ${generateFluidCalc(43, 53.75, BREAKPOINTS.XL, BREAKPOINTS["2XL"])};
        }
      }
    }
  }

  .tagline {
    text-align: center;

    ${MEDIA_QUERIES.M} {
      padding: 0 0 var(--spacing--L) 0;
    }

    span {
      display: inline-block;
      margin-bottom: 0;
    }

    ${MEDIA_QUERIES.M} {
      text-align: right;
    }
  }
`
