import { getCookie } from "@utils"

export const submitFormData: (formEl: HTMLFormElement, formId: string) => Promise<any> = async (formEl, formId) => {
  const formData = new FormData(formEl)

  const reqJson: { [k: string]: string | null } = {
    formId,
    hubspotutk: getCookie("hubspotutk"),
    pageUri: window.location.href,
    pageName: document.querySelector("h1")?.innerText || "",
  }

  for (const [k, v] of formData) {
    reqJson[k] = v
  }

  // Get the User's IP address for HubSpot tracking
  const ipAddress = await fetch('https://api.ipify.org?format=json')
    .then(res => res.json())
    .then(({ ip }) => ip)

  return fetch("/api/hubspot/submitForm", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      ...reqJson,
      ipAddress,
    })
  })
    .then(res => res.json())
}