export const SITE_FOOTER_DATA = {
  columns: [
    {
      title: "Product",
      items: [
        {
          title: "Product Overview",
          url: "/platform/",
          isNew: false,
        },
        {
          title: "Shared Inbox",
          url: "/inbox/",
          isNew: false,
        },
        {
          title: "Self-service",
          url: "/self-service/",
          isNew: false,
        },
        {
          title: "Knowledge Base",
          url: "/knowledge-base/",
          isNew: false,
        },
        {
          title: "Live Chat",
          url: "/beacon-messaging/",
          isNew: false,
        },
        {
          title: "Proactive Messaging",
          url: "/proactive-messages/",
          isNew: false,
        },
        {
          title: "Ominchannel Support",
          url: "/customer-support-channels/",
          isNew: false,
        },
        {
          title: "Automation",
          url: "/help-desk-workflows/",
          isNew: false,
        },
        {
          title: "Mobile",
          url: "/help-desk-app/",
          isNew: false,
        },
      ]
    },
    {
      title: "Compare",
      items: [
        {
          title: "Zendesk",
          url: "/compare/zendesk/",
          isNew: false,
        },
        {
          title: "Intercom",
          url: "/compare/intercom/",
          isNew: false,
        },
        {
          title: "Freshdesk",
          url: "/compare/freshdesk/",
          isNew: false,
        },
        {
          title: "Gorgias",
          url: "/compare/gorgias/",
          isNew: false,
        },
        {
          title: "Front",
          url: "/compare/frontapp/",
          isNew: false,
        },
      ]
    },
    {
      title: "Company",
      items: [
        {
          title: "About",
          url: "/about/",
          isNew: false,
        },
        {
          title: "Careers",
          url: "/company/careers/",
          isNew: false,
        },
        {
          title: "Newsletter",
          url: "/newsletter/supportive/",
          isNew: false,
        },
        {
          html: "<a href='/company/legal/terms-of-service/'>Terms</a> &amp; <a href='/company/legal/privacy/'>Privacy</a>",
          isNew: false,
        },
        {
          title: "Accessibility",
          url: "/company/legal/accessibility-statement/",
          isNew: false,
        },
        {
          html: "<a href='#' id='footerDoNotSell'>Do Not Sell</a>",
          isNew: false,
        },
      ]
    },
    {
      title: "Support",
      items: [
        {
          title: "Developers",
          url: "https://developer.helpscout.com/",
          isNew: false,
        },
        {
          title: "Help Docs",
          url: "https://docs.helpscout.com/",
          isNew: false,
        },
        {
          html: "<a href='#contact-us' id='footerContactUs'>Contact</a>",
          isNew: false,
        },
        {
          title: "Status",
          url: "https://status.helpscout.com/",
          isNew: false,
        }
      ]
    }
  ]
}