import React, { FC } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Icon, { ICON_TYPES } from "@components/Icon/Icon"
import { MEDIA_QUERIES } from "@constants"
import Badge, { BADGE_COLORS, BADGE_SIZES } from "@components/Badge/Badge"

type SiteNavDrawerFooterPoweredByProps = {
  tabIndex?: number,
  id: string,
  items?: {
    icon?: string | null,
    isNew?: boolean | null,
    title?: string | null,
    url?: string | null,
  }[] | null
}

const SiteNavDrawerFooterPoweredByDIV = styled.div`
  align-self: flex-end;
  flex: 0 0 100%;
  margin-top: calc(var(--spacing--M) * -1);
  position: relative;
  width: 100%;

  h6 {
    font-size: calc(var(--font-size--L) * .5);
    letter-spacing: var(--letter-spacing--widest);
    margin: 0;
    padding: var(--spacing--base-0-5) 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;

    > span {
      align-items: center;
      background: var(--color--basics-white);
      display: inline-flex;
      height: calc(var(--font-size--S) * 1.25);
      padding-right: 10px;
      position: relative;
      z-index: 1;
      
      span {
        display: block;
        margin-top: calc(var(--font-size--S) * 0.2);
      }
    }

    svg {
      width: calc(var(--font-size--S) * 1.25);
      height: calc(var(--font-size--S) * 1.25);
    }

    &::after {
      background: linear-gradient(272deg, #FFF 5.11%, rgba(255, 255, 255, 0.00) 128.96%), linear-gradient(239deg, rgba(220, 106, 186, 0.24) -11.94%, rgba(71, 163, 255, 0.24) 50.87%);
      content: "";
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      translate: 0 -0.5px;
      width: 100%;
      z-index: 0;
    }
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: var(--spacing--XS);

    ${MEDIA_QUERIES.L} {
      row-gap: var(--spacing--base-0-25);
    }
  }

  a {
    align-items: center;
    color: var(--color--text-dark);
    display: flex;
    margin-bottom: 0;
    white-space: nowrap;

    .Icon {
      display: inline-block;
      margin-top: calc(var(--font-size--S) * -.0125);
      margin-right: calc(var(--font-size--S) * .125);
      margin-left: calc(var(--font-size--S) * -.125);
    }

    .Badge {
      margin-left: calc(var(--font-size--S) * .375);
      margin-top: calc(var(--font-size--S) * .1);
    }
    
    &:hover svg path {
      stroke: var(--color--action-hovered);
    }
  }

  ${MEDIA_QUERIES.M} {
    margin-top: 0;
  }

  ${MEDIA_QUERIES.XL} {
    --SiteNav--powered-by--border-radius: calc((var(--font-size--XS) * var(--line-height--relaxed) + var(--spacing--base-0-75) * 2) / 2);

    background: linear-gradient(270deg, #FFF 5.11%, rgba(255, 255, 255, 0.00) 128.96%), linear-gradient(170deg, rgba(220, 106, 186, 0.24) -11.94%, rgba(71, 163, 255, 0.24) 50.87%);
    border-radius: var(--SiteNav--powered-by--border-radius) 0 0 var(--SiteNav--powered-by--border-radius); // arbitrary number to get those nice rounded corners
    padding: var(--spacing--base-0-75) 0 var(--spacing--base-0-75) var(--spacing--base-1-5);
    position: relative;

    &::before {
      background: white;
      content: "";
      display: block;
      height: calc(100% - 2px);
      left: 1px;
      position: absolute;
      top: 1px;
      width: calc(100% - 1px);
      border-radius: calc(var(--SiteNav--powered-by--border-radius) - 1px) 0 0 calc(var(--SiteNav--powered-by--border-radius) - 1px);
    }

    > div {
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 1;
    }

    h6 {
      width: auto;
      white-space: nowrap;
      padding: 0;
      height: fit-content;

      span {
        background: transparent;
      }

      &::after {
        display: none;
      }
    }
  }
`

const SiteNavDrawerFooterPoweredBy: FC<SiteNavDrawerFooterPoweredByProps> = ({
  id,
  items,
  tabIndex,
}) => (
  <SiteNavDrawerFooterPoweredByDIV>
    <div>
      <h6>
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.75 13.25H6.75L13.25 4.75V10.75H17.25L10.75 19.25V13.25Z" fill={`url(#powered-by-svg--${id})`} />
            <defs>
              <linearGradient id={`powered-by-svg--${id}`} x1="7" y1="-0.288818" x2="-1.5" y2="14.7112" gradientUnits="userSpaceOnUse">
                <stop stopColor="#DC6ABA" />
                <stop offset="1" stopColor="#47A3FF" />
              </linearGradient>
            </defs>
          </svg>
          <span>Powered by</span>
        </span>
      </h6>
      {items?.map(item => (
        <Link
          className="type--text-medium-S"
          key={item.url}
          tabIndex={tabIndex}
          to={item.url}
        >
          {item.icon && (
            <Icon
              color="var(--color--text-dark)"
              size="calc(var(--font-size--S) * 1.25)"
              type={ICON_TYPES[item.icon as ICON_TYPES]}
            />
          )}
          {item.title}
          {item.isNew && (
            <Badge
              color={BADGE_COLORS.PURPLE}
              isInverted
              size={BADGE_SIZES.XS}
            >
              NEW
            </Badge>
          )}
        </Link>
      ))}
    </div>
  </SiteNavDrawerFooterPoweredByDIV>
)

export default SiteNavDrawerFooterPoweredBy
