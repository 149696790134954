import React, { FC, useEffect, useRef } from "react"
import styled from "styled-components"

import { generateConditionalClassName, generateFluidCalc, isClientSide } from "@utils"

import { SITE_NAV_MOCK_DATA } from "../SiteNav.data"

import SiteNavDrawerCard from "./SiteNavDrawerCard/SiteNavDrawerCard"
import SiteNavDrawerFooterCta from "./SiteNavDrawerFooterCta/SiteNavDrawerFooterCta"
import SiteNavDrawerFooterPoweredBy from "./SiteNavDrawerFooterPoweredBy/SiteNavDrawerFooterPoweredBy"
import SiteNavDrawerFooterSmallList from "./SiteNavDrawerFooterSmallList/SiteNavDrawerFooterSmallList"
import SiteNavDrawerList from "./SiteNavDrawerList/SiteNavDrawerList"
import { BREAKPOINTS, MEDIA_QUERIES } from "@constants"

enum NAV_COLUMN_ITEM_LAYOUTS {
  CARD = "CARD",
  FEATURED_CARD = "FEATURED_CARD",
  LIST = "LIST",
}

enum NAV_COLUMN_FOOTER_LAYOUTS {
  CTA = "CTA",
  POWERED_BY = "POWERED_BY",
  SMALL_LIST = "SMALL_LIST",
}

const SiteNavDrawerDIV = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing--M) * 2) calc(var(--button--padding-x-L) * 2);
  height: 0;
  margin: 0 -15px;
  opacity: 0;
  overflow: hidden;
  padding: 0 15px;

  &.is-active {
    padding-bottom: var(--spacing--M);
    padding-top: var(--spacing--M);
  }

  ${MEDIA_QUERIES.M} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing--M) calc(var(--spacing--M) * 2);
  }

  ${MEDIA_QUERIES.L} {
    padding-top: var(--spacing--M);
    pointer-events: none;
    width: calc(100% + 30px);
    height: calc(${generateFluidCalc(221, 250, BREAKPOINTS.L, BREAKPOINTS.XL)} + var(--spacing--M) * 2 - var(--spacing--base-2));

    &,
    &.is-active {
      padding-bottom: calc(var(--spacing--M) - var(--spacing--base-2));
    }

    &.is-active {
      pointer-events: all;
    }
  }

  ${MEDIA_QUERIES.XL} {
    height: calc(${generateFluidCalc(250, 312, BREAKPOINTS.XL, BREAKPOINTS["2XL"])} + var(--spacing--M) * 2 - var(--spacing--base-2));

    .product & {
      gap: var(--spacing--M);
    }
  }
`

const SiteNavDrawerColumnDIV = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--SiteNav--drawer-column--gap-y) var(--SiteNav--drawer-column--gap-x);
  position: relative;

  &:first-child::after {
    background: var(--color--charcoal-200);
    content: "";
    display: block;
    position: absolute;
    bottom: calc(var(--spacing--M) * -1);
    height: 1px;
    width: 100%;
  }

  ${MEDIA_QUERIES.M} {
    &:first-child::after {
      height: 100%;
      width: 1px;
      bottom: 0;
      right: calc(var(--spacing--M) * -1);
    }

    &:first-child {
      flex: 0 0 calc(7 / 12 * 100% - var(--spacing--M) * 2);
    }

    &:nth-child(2) {
      flex: 0 0 calc(5 / 12 * 100%);
    }

    .product & {
      flex: 0 0 100%;
      flex-wrap: nowrap;

      .SiteNavDrawerList {
        flex: 0 0 calc(25% - var(--SiteNav--drawer-column--gap-x) / 2);
      }

      .SiteNavDrawerFooterCta {
        flex: 0 0 calc(50% - var(--SiteNav--drawer-column--gap-x) / 2);
        align-self: flex-start;
        margin-top: 0;
      }
    }
  }

  ${MEDIA_QUERIES.L} {
    flex-direction: row;
    
    &:first-child {
      flex: 0 0 calc(8 / 12 * 100% - var(--spacing--M) * 2);
    }

    &:nth-child(2) {
      flex: 0 0 calc(4 / 12 * 100%);
    }

    .product & {
      flex-wrap: wrap;

      .SiteNavDrawerList {
        flex: 0 0 calc(50% - var(--SiteNav--drawer-column--gap-x) / 2);
      }

      .SiteNavDrawerFooterCta {
        flex: 0 0 100%;
        align-self: flex-end;
        margin-top: calc(var(--spacing--M) - var(--SiteNav--drawer-column--gap-y));
      }
    }
  }

  ${MEDIA_QUERIES.XL} {
    /* PRODUCT spacing */
    .product & {
      &::after {
        display: none;
      }

      &:first-child {
        flex: 0 0 calc(9 / 12 * 100% - var(--spacing--M) * 2);
      }

      &:nth-child(2) {
        flex: 0 0 calc(3 / 12 * 100% + var(--spacing--M));
      }
    }
    /* SOLUTIONs & COMPANY spacing */
    .solutions &,
    .company & {
      &:first-child {
        flex: 0 0 calc(9 / 12 * 100% - var(--spacing--M) * 2);
      }

      &:nth-child(2) {
        flex: 0 0 calc(3 / 12 * 100%);
      }
    }
  }
`

const SiteNavDrawer: FC<{
  id: string,
  isActive?: boolean,
  isMobile?: boolean,
  columns: typeof SITE_NAV_MOCK_DATA["navItems"][0]["columns"]
  tabIndex?: number,
}> = ({
  columns,
  id,
  isActive,
  isMobile,
  tabIndex,
}) => {
    if (!columns || columns.length === 0) return null

    const elRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
      if (!isClientSide() || !elRef.current) return

      if (window.innerWidth >= BREAKPOINTS.L) {
        elRef.current.style.transition = "opacity 0.3s ease-in-out"
        elRef.current.style.height = ""
        elRef.current.style.opacity = isActive ? "1" : "0"
        return
      }

      if (!isActive) {
        elRef.current.style.transition = "height 0.3s 0.1s, opacity 0.3s, padding 0.3s 0.1s"
        elRef.current.style.height = "0"
        elRef.current.style.opacity = "0"
        return
      }

      elRef.current.style.height = "auto"
      elRef.current.style.transition = "none"
      const _height = elRef.current.getBoundingClientRect().height
      elRef.current.style.height = "0"
      elRef.current.style.transition = "height 0.3s, opacity 0.3s 0.1s, padding 0.3s"

      // Defer
      setTimeout(() => {
        if (elRef.current) {
          elRef.current.style.height = `${_height}px`
          elRef.current.style.opacity = "1"
        }
      }, 0)
    }, [isActive])

    return (
      <SiteNavDrawerDIV
        className={generateConditionalClassName({
          SiteNavDrawer: true,
          "is-active": !!isActive,
        })}
        ref={elRef}
      >
        {columns.map((column, i) => !column ? null : (
          <React.Fragment key={`${id}-${i}`}>
            <SiteNavDrawerColumnDIV
              className={generateConditionalClassName({
                [`item-count--${column.items.length}`]: true,
              })}
            >
              {column.items.map(columnItem => !columnItem ? null : (
                <React.Fragment key={columnItem.title}>
                  {(
                    columnItem.layout === NAV_COLUMN_ITEM_LAYOUTS.CARD ||
                    columnItem.layout === NAV_COLUMN_ITEM_LAYOUTS.FEATURED_CARD
                  ) && (
                      <SiteNavDrawerCard
                        tabIndex={tabIndex}
                        {...columnItem}
                      />
                    )}
                  {columnItem.layout === NAV_COLUMN_ITEM_LAYOUTS.LIST && (
                    <SiteNavDrawerList
                      tabIndex={tabIndex}
                      {...columnItem}
                    />
                  )}
                </React.Fragment>
              ))}
              {column.footer && column.footer.layout === NAV_COLUMN_FOOTER_LAYOUTS.CTA && (
                <SiteNavDrawerFooterCta
                  tabIndex={tabIndex}
                  {...column.footer}

                />
              )}
              {column.footer && column.footer.layout === NAV_COLUMN_FOOTER_LAYOUTS.SMALL_LIST && (
                <SiteNavDrawerFooterSmallList
                  tabIndex={tabIndex}
                  {...column.footer}
                />
              )}
              {!isMobile && column.footer && column.footer.layout === NAV_COLUMN_FOOTER_LAYOUTS.POWERED_BY && (
                <SiteNavDrawerFooterPoweredBy
                  tabIndex={tabIndex}
                  id="desktop"
                  {...column.footer}

                />
              )}
            </SiteNavDrawerColumnDIV>
            {isMobile && column.footer && column.footer.layout === NAV_COLUMN_FOOTER_LAYOUTS.POWERED_BY && (
              <SiteNavDrawerFooterPoweredBy
                tabIndex={tabIndex}
                id="mobile"
                {...column.footer}

              />
            )}
          </React.Fragment>
        ))}
      </SiteNavDrawerDIV>
    )
  }

export default SiteNavDrawer
