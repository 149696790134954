import React, { FC, ReactNode } from "react"
import styled, { css } from "styled-components"

import { generateConditionalClassName } from "@utils"

export enum BADGE_COLORS {
  CHARCOAL = "CHARCOAL",
  CHARCOAL_TINT = "CHARCOAL_TINT",
  CLAY = "CLAY",
  CLAY_TINT = "CLAY_TINT",
  COBALT = "COBALT",
  GREEN = "GREEN",
  MAGENTA = "MAGENTA",
  ORANGE = "ORANGE",
  PURPLE = "PURPLE",
  RED = "RED",
  SLATE = "SLATE",
  SLATE_TINT = "SLATE_TINT",
  YELLOW = "YELLOW",
}

export enum BADGE_SIZES {
  XS = "XS",
  S = "S",
  M = "M",
}

const BADGE_BACKGROUND_COLOR_MAP: { [k in BADGE_COLORS]: string } = {
  CHARCOAL: "background-color--charcoal-700",
  CHARCOAL_TINT: "background-color--charcoal-200",
  CLAY: "background-color--clay-700",
  CLAY_TINT: "background-color--clay-200",
  COBALT: "background-color--cobalt-700",
  GREEN: "background-color--green-600",
  MAGENTA: "background-color--magenta-500",
  ORANGE: "background-color--orange-600",
  PURPLE: "background-color--purple-600",
  RED: "background-color--red-600",
  SLATE: "background-color--slate-600",
  SLATE_TINT: "background-color--slate-200",
  YELLOW: "background-color--yellow-600",
}

const BADGE_INVERTED_BORDER_COLOR_MAP: { [k in BADGE_COLORS]: string } = {
  CHARCOAL: "var(--color--charcoal-400)",
  CHARCOAL_TINT: "var(--color--charcoal-300)",
  CLAY: "var(--color--clay-400)",
  CLAY_TINT: "var(--color--clay-300)",
  COBALT: "var(--color--cobalt-300)",
  GREEN: "var(--color--green-300)",
  MAGENTA: "var(--color--magenta-300)",
  ORANGE: "var(--color--orange-300)",
  PURPLE: "var(--color--purple-300)",
  RED: "var(--color--red-300)",
  SLATE: "var(--color--slate-300)",
  SLATE_TINT: "var(--color--slate-300)",
  YELLOW: "var(--color--yellow-300)",
}

const BADGE_INVERTED_COLOR_MAP: { [k in BADGE_COLORS]: string } = {
  CHARCOAL: "color--text-default",
  CHARCOAL_TINT: "color--text-light",
  CLAY: "color--text-default",
  CLAY_TINT: "color--text-light",
  COBALT: "color--cobalt-700",
  GREEN: "color--green-700",
  MAGENTA: "color--magenta-700",
  ORANGE: "color--orange-700",
  PURPLE: "color--purple-700",
  RED: "color--red-700",
  SLATE: "color--slate-600",
  SLATE_TINT: "color--text-light",
  YELLOW: "color--yellow-700",
}

export const BadgeSizeXSCSS = css`
  display: inline-block;
  font-weight: var(--font-weight--medium);
  border-radius: var(--border-radius--XS);
  font-size: calc(var(--font-size--L) / 2);
  font-weight: var(--font-weight--semibold);
  letter-spacing: var(--letter-spacing--widest);
  line-height: var(--line-height--relaxed);
  padding: calc(var(--spacing--base-1) * 0.03125) calc(var(--spacing--base-1) * 0.1875) 0;
  text-transform: var(--text-transform--uppercase);
`

export const BadgeSizeSCSS = css`
  display: inline-block;
  font-weight: var(--font-weight--medium);
  border-radius: calc(var(--border-radius--XL) * 2);
  font-size: var(--font-size--XS);
  letter-spacing: var(--letter-spacing--normal);
  line-height: var(--line-height--snug);
  padding: 0 calc(var(--spacing--base-1) * 0.375) calc(var(--spacing--base-1) * 0.0625);
`

export const BadgeSizeMCSS = css`
  display: inline-block;
  font-weight: var(--font-weight--medium);
  border-radius: calc(var(--border-radius--XL) * 2);
  letter-spacing: var(--letter-spacing--normal);
  font-size: var(--font-size--S);
  line-height: var(--line-height--relaxed);
  padding: 0 calc(var(--spacing--base-1) * 0.625) calc(var(--spacing--base-1) * 0.0625);
`

export const BadgeSPAN = styled.span`
  &.size--${BADGE_SIZES.XS} {
    ${BadgeSizeXSCSS}
  }

  &.size--${BADGE_SIZES.S} {
    ${BadgeSizeSCSS}
  }

  &.size--${BADGE_SIZES.M} {
    ${BadgeSizeMCSS}
  }
`

type BadgeProps = {
  children: ReactNode,
  className?: string
  color?: BADGE_COLORS
  isInverted?: boolean,
  size?: BADGE_SIZES,
  useMonoFont?: boolean,
}

const Badge: FC<BadgeProps> = ({
  children,
  className,
  color = BADGE_COLORS.CLAY_TINT,
  isInverted,
  size = BADGE_SIZES.M,
  useMonoFont,
}) => {
  const isTint = color.includes("TINT")
  return (
    <BadgeSPAN
      className={generateConditionalClassName({
        Badge: true,
        [`${BADGE_INVERTED_COLOR_MAP[color]}`]: !!isInverted,
        [`${className}`]: !!className,
        [`${isInverted ? `` : BADGE_BACKGROUND_COLOR_MAP[color]}`]: true,
        [`color--basics-white`]: !isInverted && !isTint,
        [`color--text-default`]: !isInverted && isTint,
        [`font-family--mono`]: size === BADGE_SIZES.M && !!useMonoFont,
        [`font-family--sans-brand`]: size === BADGE_SIZES.XS,
        [`font-family--sans`]: [BADGE_SIZES.S, BADGE_SIZES.M].includes(size) && !useMonoFont,
        [`is-inverted`]: !!isInverted,
        [`size--${size}`]: true,
      })}
      style={{ boxShadow: isInverted ? `0 0 0 1px ${BADGE_INVERTED_BORDER_COLOR_MAP[color]}` : undefined }}
    >
      {children}
    </BadgeSPAN>
  )
}

export default Badge
