import React, { FC } from "react"

import RedditScript from "./RedditScript/RedditScript"
import UnifyScript from "./UnifyScript/UnifyScript"

import {
  INCLUDE_UNIFY_PATHS,
} from "./OptionalScripts.constants"

interface OptionalScriptsProps {
  path: string
}

const OptionalScripts: FC<OptionalScriptsProps> = ({ path }) => {
  const includeReddit = path.includes("/see-it-in-action/")
  const includeUnify = !!INCLUDE_UNIFY_PATHS.find(p => path.includes(p))

  return (
    <>
      {includeReddit && <RedditScript />}
      {includeUnify && <UnifyScript />}
    </>
  )
}

export default OptionalScripts
