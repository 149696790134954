import { useProfile } from "@ninetailed/experience.js-gatsby"
import { isClientSide } from "@utils"
import {
  FC,
  useEffect,
  useState
} from "react"

const NinetailedListener: FC = () => {
  const { status } = useProfile()
  const [sufVariantIndex, setSufVariantIndex] = useState<number | undefined>()

  useEffect(() => {
    if (!isClientSide() || status !== "success") return

    const sufExperience = (window.ninetailed?.experiences || []).find(e => e && e.experienceId === "7xYSE9FwrGjA1rjAIJLoMy") || {}

    const { variantIndex } = sufExperience

    if (variantIndex !== 0 && variantIndex !== 1) return

    setSufVariantIndex(variantIndex)
  }, [status])

  useEffect(() => {
    if (!isClientSide() || sufVariantIndex === undefined) return

    //
    // Update any trial links to include `?hs_tvar=50-{A/B}` depending on the Ninetailed variant served
    //
    const allTrialLinks = Array.from(document.querySelectorAll<HTMLAnchorElement>(`a[href*="/members/register"]`))

    allTrialLinks.forEach(link => {
      const splitUrl = link.href.split("?")
      const urlParams = new URLSearchParams(splitUrl[1])
      urlParams.set("hs_tvar", sufVariantIndex === 1 ? "51-B" : "51-A")
      const newUrl = `${splitUrl[0]}?${urlParams.toString()}`
      link.href = newUrl
    })
  })

  return null
}

export default NinetailedListener
