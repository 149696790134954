import { HubspotFormLegalCheckboxFragmentFragment } from "@graphqlTypes"
import { generateConditionalClassName, oneLineHelpScout } from "@utils"
import React, { FC, useState } from "react"
import styled from "styled-components"
import ValidationIcon from "../ValidationIcon/ValidationIcon"

const LegalConsentCheckboxLABEL = styled.label`
  && {
    position: relative;
    display: flex;
    gap: var(--spacing--base-0-75);
    cursor: pointer;

    p {
      white-space: nowrap;
      margin: 0;
      font-size: var(--font-size--S);

      &:last-of-type {
        color: var(--color--text-light);
      }
    }

    &.subscription-checkbox {
      p:first-of-type {
        color: var(--color--text-headline);
        font-family: var(--font-family--sans-brand);
        font-feature-settings: var(--font-family--sans-brand--font-feature-settings);
        font-size: var(--font-size--L);
        font-weight: var(--font-weight--semibold);
        line-height: var(--line-height--tight);
        margin-bottom: calc(var(--spacing--base-0-75) / 2);
      }
    }

    .validation-icon {
      position: static;
      transform: none;
      margin-left: calc(var(--spacing--base-0-75) * -1);
    }

    &.is-disabled {
      cursor: default;
      pointer-events: none;
    }
  }
`

const LegalConsentCheckbox: FC<HubspotFormLegalCheckboxFragmentFragment & {
  hasMultipleOptions?: boolean,
  isCompact?: boolean,
  isDisabled?: boolean,
  isSubscription?: boolean,
  isInvalid?: boolean,
}> = ({
  hasMultipleOptions,
  isCompact,
  isDisabled,
  isInvalid,
  isSubscription,
  label,
  required,
  subscriptionTypeId,
}) => {
    if (!label || !subscriptionTypeId) return null

    const name = `legal--${subscriptionTypeId}`
    const [isChecked, setIsChecked] = useState<boolean>(false)

    return (
      <LegalConsentCheckboxLABEL
        className={generateConditionalClassName({
          "subscription-checkbox": !!isSubscription,
          "is-disabled": !!isDisabled,
        })}
      >
        <input
          checked={isChecked}
          className={generateConditionalClassName({
            "has-error" : !!isInvalid,
            "size--S": !!isCompact,
          })}
          disabled={isDisabled}
          name={name}
          onChange={() => setIsChecked(_isChecked => !_isChecked)}
          required={!!required}
          type="checkbox"
        />
        <input type="hidden" name={`${name}__TEXT`} value={label} />
        <div dangerouslySetInnerHTML={{ __html: oneLineHelpScout(label) }} />
        {isInvalid && (
          <ValidationIcon
            fieldName={name}
            message={
              hasMultipleOptions ?
                "Please select at least one option" :
                undefined
            }
            size="calc(var(--font-size--S) * var(--line-height--relaxed))"
          />
        )}
      </LegalConsentCheckboxLABEL>
    )
  }

export default LegalConsentCheckbox
