import React, { MouseEvent, useEffect, useState } from "react"
import { Link } from "gatsby"

import { convertStringToKebabCase, isClientSide } from "@utils"

import Badge, { BADGE_COLORS, BADGE_SIZES } from "@components/Badge/Badge"
import Icon, { ICON_TYPES } from "@components/Icon/Icon"

import SiteFooterSocialLinks from "./SiteFooterSocialLinks/SiteFooterSocialLinks"
import SiteFooterResponsibility from "./SiteFooterResponsibility/SiteFooterResponsibility"

import { SITE_FOOTER_DATA } from "./SiteFooter.data"
import { SiteFooterFOOTER } from "./SiteFooter.styles"
import SiteFooterBigLogo from "./SiteFooterBigLogo/SiteFooterBigLogo"

declare global {
  interface Window {
    transcend: any
  }
}

const { columns } = SITE_FOOTER_DATA

const SiteFooter = () => {
  const [logoHighlightOpacity, setLogoHighlightOpacity] = useState<number>(0)
  const [clientXY, setClientXY] = useState<[x: number, y: number]>([0, 0])

  // Add Do Not Sell consent manager pop-up using transcend API - https://github.com/transcend-io/consent-manager-ui#donotselldisclosure
  useEffect(() => {
    if (!isClientSide()) return

    const doNotSell = document.querySelector("#footerDoNotSell")

    const checkAPI = (timeout: number) => {
      const startTime = Date.now()

      const pollAPI = () => {
        if (window.transcend) {
          // API is loaded
          if (doNotSell) {
            doNotSell.addEventListener("click", (event) => {
              event.preventDefault()
              window.transcend.doNotSell(event)
            })
          }
        } else {
          // API has not yet loaded
          const currentTime = Date.now()
          const elapsedTime = currentTime - startTime

          if (elapsedTime >= timeout) {
            console.error('Timeout occurred while waiting for Transcend.')
            return
          }

          // Schedule another check
          setTimeout(pollAPI, 100)
        }
      }
      pollAPI()
    }

    // Specify polling timeout duration (in milliseconds)
    const timeoutDuration = 5000
    if (doNotSell) checkAPI(timeoutDuration)
  }, [])
  
  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    setClientXY([e.clientX, e.clientY])
  }

  const handleMouseEnter = () => {
    setLogoHighlightOpacity(0.2)
  }

  const handleMouseLeave = () => {
    setLogoHighlightOpacity(0)
  }


  return (
    <SiteFooterFOOTER
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="container">
        <div className="flex">
          {columns.map(column => (
            <div
              className={`SiteFooter--column ${convertStringToKebabCase(column.title)}`}
              key={column.title}
            >
              <h4 className="type--headline-medium-S">{column.title}</h4>
              {column.items.map(item => {
                if (!item.url && item.html) {
                  return (
                    <span
                      key={item.html}
                      dangerouslySetInnerHTML={{ __html: item.html }}
                    />
                  )
                }

                if (!item.url) return null

                if (item.url.includes("//")) {
                  return (
                    <a
                      href={item.url}
                      key={item.title}
                    >
                      {item.title}
                      {item.title === "Status" && (
                        <Badge
                          className="status-badge"
                          color={BADGE_COLORS.CHARCOAL}
                          size={BADGE_SIZES.XS}
                        >
                          <Icon
                            size="calc(var(--font-size--XS) * .8)"
                            type={ICON_TYPES["triangle"]}
                          />
                          99.99%
                        </Badge>
                      )}
                      <Icon
                        size="calc(var(--font-size--XS) * .8)"
                        type={ICON_TYPES["arrow-up-right"]}
                      />
                    </a>
                  )
                }

                return (
                  <Link
                    key={item.title}
                    to={item.url}
                  >
                    {item.title}
                    {item.isNew && (
                      <Badge
                        color={BADGE_COLORS.COBALT}
                        size={BADGE_SIZES.XS}
                      >
                        NEW
                      </Badge>
                    )}
                  </Link>
                )
              })}
            </div>
          ))}
          <div className="SiteFooter--column desktop-meta">
            <SiteFooterSocialLinks />
            <SiteFooterResponsibility />
            <div className="type--text-XS color--charcoal-600">&copy; {(new Date()).getFullYear()} Help&nbsp;Scout</div>
          </div>
          <div className="SiteFooter--column mobile-meta">
            <SiteFooterResponsibility />
          </div>
          <div className="SiteFooter--column mobile-meta social-links-copyright">
            <SiteFooterSocialLinks />
            <div className="type--text-XS color--charcoal-600">&copy; {(new Date()).getFullYear()} Help&nbsp;Scout</div>
          </div>
          <div className="tagline flex-item--1">
            <span className="type--serif-2XL font-size--XL color--charcoal-300"><em>The all-in-one platform for</em></span>
            {" "}
            <span className="type--headline-medium-M color--charcoal-300">delightful conversations</span>
          </div>
        </div>
      </div>
      <SiteFooterBigLogo
        clientX={clientXY[0]}
        clientY={clientXY[1]}
        opacity={logoHighlightOpacity}
      />
    </SiteFooterFOOTER>
  )
}

export default SiteFooter
