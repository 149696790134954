import { graphql, useStaticQuery } from "gatsby"

import { CommonUrlsAndNumbersQueryQuery } from "@graphqlTypes"

const useCommonUrlsAndNumbers = () => {
  const {
    urlsAndNumbersJson
  } = useStaticQuery<CommonUrlsAndNumbersQueryQuery>(graphql`
    query CommonUrlsAndNumbersQuery {
      urlsAndNumbersJson: contentfulJsonData(contentful_id:{eq:"2vxqznN6DGkTSwK2Q76MhX"}) {
        json {
          demoUrl
          loginUrl
          signupFreeUrl
          signupPlusUrl
          signupStandardUrl
          trialDaysLength
        }
      }
    }
  `)

  try {
    if (!urlsAndNumbersJson || !urlsAndNumbersJson.json) {
      throw new Error("Unable to parse JSON")
    }

    return urlsAndNumbersJson.json

  } catch (err) {
    console.error(err)

    return {}
  }
}

export default useCommonUrlsAndNumbers
