import React, { ReactNode } from "react"
import rehypeReact from "rehype-react"
import xss from "xss"
import { graphql, useStaticQuery } from "gatsby"

import HubspotForm from "@components/HubspotForm/HubspotForm"
import InfoPopover, { POPOVER_INDICATOR_TYPES } from "@components/InfoPopover/InfoPopover"

import { ContentDIV } from "@components/Content/Content.styles"

import {
  ContentTableWrapperDIV,
  ContentTableScrollerDIV,
  ContentTableTABLE,
} from "@components/Content/components/ContentTable/ContentTable.styles"
import ContentBreakout from "@components/Content/components/ContentBreakout/ContentBreakout"

export const renderAstWithWidgets = (hast: any): React.ReactElement => {
  const { Compiler } = new rehypeReact({
    createElement: React.createElement,
    components: {
      "callout": ({ children }) => (
        <div className="background-color--clay-100 border-color--clay-300 border-style--solid border-width--1 padding-top--S padding-left--S padding-right--S">
          {children}
        </div>
      ),
      "subprocessor-form": () => {
        const { hubspotForm } = useStaticQuery(graphql`
          query LegacyContentHubspotFormQuery {
            hubspotForm(alternative_id:{eq:"e684c45e-86a7-4fb7-bafb-1b4d2fd2444c"}) {
              ...HubspotFormFragment
            }
          }
        `)
        if (!hubspotForm) return null
        return (
          <HubspotForm {...hubspotForm} />
        )
      },
      "img": ({ ...props }) => <img {...props} className="margin-bottom--S" />,
      "table": ({ children }) => {
        return (
          <ContentBreakout>
            <div className="container">
              <div className="grid">
                <div className="column column--2-12-L">
                  <ContentTableWrapperDIV>
                    <ContentTableScrollerDIV>
                      <ContentTableTABLE className="no-zebra">
                        {children}
                      </ContentTableTABLE>
                    </ContentTableScrollerDIV>
                  </ContentTableWrapperDIV>
                </div>
              </div>
            </div>
          </ContentBreakout>
        )
      },
    },
    Fragment: React.Fragment,
  })

  return (
    <ContentDIV>
      {Compiler(hast)}
    </ContentDIV>
  )
}

export const insertInfoPopovers = (str: string): ReactNode => {
  const infoPopoverRegex = /<info content="(.+?)"\>(.+)<\/info>/
  const infoPopover = str.match(infoPopoverRegex)

  if (!infoPopover) return <span dangerouslySetInnerHTML={{ __html: xss(str.trim()) }} />

  const splitStr = str.replace(/\(.+\)/, "").split(infoPopoverRegex)

  return (
    <span>
      {splitStr[0]}
      <InfoPopover label={infoPopover[2]} displayType={POPOVER_INDICATOR_TYPES.UNDERLINE}>{infoPopover[1]}</InfoPopover>
      {splitStr[3]}
    </span>
  )
}
