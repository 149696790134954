import { Script } from "gatsby"
import React, { ReactElement } from "react"

const ZOOM_INFO_SCRIPT = `
window[(function(_5AM,_dd){var _df='';for(var _TD=0;_TD<_5AM.length;_TD++){var _ZZ=_5AM[_TD].charCodeAt();_ZZ-=_dd;_ZZ!=_TD;_df==_df;_ZZ+=61;_ZZ%=94;_dd>9;_ZZ+=33;_df+=String.fromCharCode(_ZZ)}return _df})(atob('YVBXeXZxbGp7Umwi'), 7)] = '1361bcc3701669921248';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_sqO,_LU){var _1e='';for(var _jk=0;_jk<_sqO.length;_jk++){var _9N=_sqO[_jk].charCodeAt();_9N-=_LU;_9N+=61;_9N%=94;_LU>9;_9N+=33;_1e==_1e;_9N!=_jk;_1e+=String.fromCharCode(_9N)}return _1e})(atob('b3t7d3pBNjZxejUjcDR6anlwd3t6NWp2dDYjcDR7aG41cXo='), 7)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });
`

const ZoomInfoScript = (): ReactElement => (
  <Script
    id="ZoomInfoScript"
    dangerouslySetInnerHTML={{ __html: ZOOM_INFO_SCRIPT }}
  />
)

export default ZoomInfoScript
