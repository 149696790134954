import { FC, useEffect } from "react"

import { isClientSide } from "@utils"

import useCommonUrlsAndNumbers from "@hooks/useCommonUrlsAndNumbers"

const QueryStringHandler: FC = () => {
  const { signupPlusUrl } = useCommonUrlsAndNumbers()

  useEffect(() => {
    if (!isClientSide()) return

    const params = new window.URLSearchParams(document.location.search)
    const promoParam = params.get("promo")

    if (!promoParam) return

    const trialLinks = document.querySelectorAll<HTMLAnchorElement>(`a[href*="${signupPlusUrl}"]`)

    if (trialLinks.length === 0) return
    
    trialLinks.forEach(trialLink => {
      const splitUrl = trialLink.href.split("?")
      const linkParams = new window.URLSearchParams(splitUrl[1])
      linkParams.set("promo", promoParam)
      trialLink.href = `${splitUrl[0]}?${linkParams.toString()}`
    })
  }, [])
  
  return null
}

export default QueryStringHandler
