// https://gist.github.com/watab0shi/46a44ba659d4effde12df594f1af57a6
export const throttle = (fn: Function, interval: number) => {
  let lastTime = Date.now() - interval
  let timerId: NodeJS.Timeout | null = null

  return (...args: any[]) => {
    const time = Date.now()

    if (timerId) clearTimeout(timerId)

    if (time - lastTime < interval) {
      timerId = setTimeout(() => {
        fn(...args)
        timerId = null
      }, interval)
      return
    }

    lastTime = time

    fn(...args)
  }
}

// https://gist.github.com/watab0shi/46a44ba659d4effde12df594f1af57a6
export const debounce = (fn: Function, delay: number) => {
  let timerId: NodeJS.Timeout | null = null

  return (...args: any[]) => {
    if (timerId) clearTimeout(timerId)

    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}
