import { FC, useEffect } from "react"

import { isClientSide, smoothScrollToElement } from "@utils"

const HashHandler: FC = () => {
  useEffect(() => {
    if (!isClientSide()) return

    const openBeacon = (navigateTo: "/ask/" | "/answers/") => {
      if (!window.Beacon) {
        setTimeout(() => openBeacon(navigateTo), 100)
        return
      }

      // If Beacon is not loaded for some reason (like only Essential cookies accepted)
      // Send the user to a mailto: link
      if (!window.Beacon("info")) {
        window.location.href = "mailto:help@helpscout.com"
        return
      }

      window.Beacon("navigate", navigateTo)
      window.Beacon("open")
      window.Beacon("once", "close", () => { window.history.pushState('', '', window.location.pathname) })
    }

    const handleHashChange = (e?: HashChangeEvent) => {
      if (e) e.preventDefault()

      const windowHash = window.location.hash.replace("#", "")

      if (!windowHash) return

      if (windowHash === "contact-us") {
        openBeacon("/ask/")
        return
      }

      if (windowHash === "open-beacon") {
        openBeacon("/answers/")
        return
      }

      smoothScrollToElement(windowHash)
    }

    window.addEventListener("hashchange", handleHashChange)

    handleHashChange()

    return () => {
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, [])

  return null
}

export default HashHandler
