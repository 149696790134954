import React, { FC } from "react"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Link } from "gatsby"
import xss from "xss"

import { MEDIA_QUERIES } from "@constants"
import { generateConditionalClassName } from "@utils"
import TextLink, { TEXT_LINK_SIZES } from "@components/TextLink/TextLink"

export enum SITE_NAV_DRAWER_CARD_LAYOUTS {
  DEFAULT = "CARD",
  CARD = "CARD",
  FEATURED_CARD = "FEATURED_CARD",
}

type SiteNavDrawerCardProps = {
  image?: IGatsbyImageData | null,
  imagePosition?: string | null,
  layout?: string | null,
  tabIndex?: number,
  text?: string,
  title: string,
  url?: string,
}

const SiteNavDrawerCardLINK = styled(Link)`
  align-items: flex-start;
  background: var(--color--clay-100);
  border-radius: var(--border-radius--S);
  border: 0.5px solid var(--color--clay-300);
  box-shadow: var(--elevation--XS);
  flex: 0 0 100%;
  display: flex;
  overflow: hidden;
  transition: box-shadow 0.3s, border-color 0.3s;

  &:hover {
    box-shadow: var(--elevation--S);
    border: 0.5px solid var(--color--clay-400);
  }

  .title {
    flex: 0 0 50%;
    padding: var(--spacing--base-1-5);

    p {
      color: var(--color--text-light);
      margin: 0;
    }
  }

  .image {
    align-self: flex-end;
    flex: 0 0 50%;
    padding-left: var(--spacing--base-1-5);
    padding-top: var(--spacing--base-1-5);

    .gatsby-image-wrapper {
      width: 100%;
      box-shadow: var(--elevation--L--on-clay);
      border-top-left-radius: var(--border-radius--S);
      overflow: hidden;
    }

    .image-position--FULL_WIDTH& {
      ${MEDIA_QUERIES.L} {
        padding-left: 0;
        padding-top: 0;
      }

      .gatsby-image-wrapper {
        aspect-ratio: 3;

        ${MEDIA_QUERIES.L} {
          aspect-ratio: auto;
          box-shadow: none;
          border-top-left-radius: 0;
        }
      }
    }
  }


  ${MEDIA_QUERIES.L} {
    flex-direction: column;
    justify-content: space-between;

    .title,
    .image {
      flex: 0 0 max-content;
    }

    .item-count--1 & {
      flex: 0 0 100%;
    }

    .item-count--2 & {
      flex: 0 0 calc(50% - var(--SiteNav--drawer-column--gap-x) / 2);
    }

    .item-count--3 & {
      flex: 0 0 calc(33.3333% - var(--SiteNav--drawer-column--gap-x) * 2 / 3);

      .image {
        padding-top: 0;
      }
    }
  }

  &.layout--${SITE_NAV_DRAWER_CARD_LAYOUTS.FEATURED_CARD} {
    min-width: calc(var(--container-width) / 2);
    border: 0.5px solid rgba(var(--color--charcoal-1200-rgb), 0.08);

    &.color--orange {
      background: var(--color--orange-100);
    }

    &.color--purple {
      background: var(--color--purple-100);
    }

    &:hover {
      border: 0.5px solid rgba(var(--color--charcoal-1200-rgb), 0.16);
    }

    .title {
      align-self: center;
      padding: var(--spacing--M);
    }

    .image .gatsby-image-wrapper {
      aspect-ratio: 1 / 1;
      box-shadow: none;
    }

    ${MEDIA_QUERIES.M} {
      flex: 0 0 calc(50% - var(--SiteNav--drawer-column--gap-x) / 2);

      .image {
        padding: 0;
      }
    }

    ${MEDIA_QUERIES.L} {
      flex-direction: row;
      min-width: 0;

      .title {
        flex: 0 0 55%;
      }

      .image {
        flex: 0 0 45%;
      }
    }
  }
`

const SiteNavDrawerCard: FC<SiteNavDrawerCardProps> = ({
  color,
  image,
  imagePosition,
  layout,
  tabIndex,
  text,
  title,
  url,
}) => {
  if (!url || !title) return null
  return (
    <SiteNavDrawerCardLINK
      className={generateConditionalClassName({
        ["SiteNavDrawerCard"]: true,
        [`color--${color}`]: !!color,
        [`image-position--${imagePosition}`]: !!imagePosition,
        [`layout--${layout}`]: !!layout,
      })}
      tabIndex={tabIndex}
      to={url}
    >
      <div className="title">
        <h4
          className={generateConditionalClassName({
            "line-height--super-tight": layout === SITE_NAV_DRAWER_CARD_LAYOUTS.FEATURED_CARD,
            "margin-bottom--0": layout !== SITE_NAV_DRAWER_CARD_LAYOUTS.FEATURED_CARD,
            "margin-bottom--base-1-25": layout === SITE_NAV_DRAWER_CARD_LAYOUTS.FEATURED_CARD,
            "type--display-4XL": layout === SITE_NAV_DRAWER_CARD_LAYOUTS.FEATURED_CARD,
            "type--headline-medium-S": layout !== SITE_NAV_DRAWER_CARD_LAYOUTS.FEATURED_CARD,
          })}
          dangerouslySetInnerHTML={{ __html: xss(title) }}
        />
        {text && (
          layout === SITE_NAV_DRAWER_CARD_LAYOUTS.FEATURED_CARD ? (
            <TextLink size={TEXT_LINK_SIZES.XS}>{text}</TextLink>
          ) : (
            <p className="type--text-XS">{text}</p>
          )
        )}
      </div>
      <div className="image">
        {image && (
          <GatsbyImage
            alt={title}
            image={image}
          />
        )}
      </div>
    </SiteNavDrawerCardLINK>
  )
}

export default SiteNavDrawerCard
