import React from "react"
import { Script } from "gatsby"

import GaScript from "./GaScript/GaScript"
import MixpanelScript from "./MixpanelScript/MixpanelScript"
import PartnerStackScript from "./PartnerStackScript/PartnerStackScript"
import PartnerStackCookies from "./PartnerStackCookies/PartnerStackCookies"
import StructuredData from "./StructuredData/StructuredData"
import ZoomInfoScript from "./ZoomInfoScript/ZoomInfoScript"

const RequiredScripts = () => (
  <>
    <Script
      data-cfasync="false"
      data-css="/css/transcendConsentManager.css"
      data-privacy-policy="/company/legal/privacy/"
      id="transcend-airgap"
      src="https://transcend-cdn.com/cm/3bdaa494-a857-49d6-a530-2b00d656dbc1/airgap.js"
    />
    <StructuredData key="StructuredData" />
    <Script
      async
      id="gtag-script"
      src="https://www.googletagmanager.com/gtag/js?id=G-ZL3HLDLQXR"
    />
    <GaScript key="GaScript" />
    <Script
      src="//js.hs-scripts.com/2760806.js"
      id="hs-script-loader"
      async
      defer
    />
    <MixpanelScript />
    <PartnerStackScript />
    <PartnerStackCookies />
    <ZoomInfoScript />
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-TTVS5W5R"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  </>
)

export default RequiredScripts
