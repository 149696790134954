import React, { FC } from "react"
import styled from "styled-components"
import { ReactSVG } from "react-svg"

import { ICON_TYPES } from "./Icon.constants"

type IconProps = {
  color?: string,
  className?: string
  id?: string
  size?: string,
  type: ICON_TYPES
}

export { ICON_TYPES }

const IconReactSVG = styled(ReactSVG) <{
  $color: IconProps["color"],
  $size: IconProps["size"]
}>`
  ${({ $size }) => `
    &,
    & > div {
      height: ${$size};
      width: ${$size};
    }

    svg {
      height: ${$size};
      width: ${$size};
      
      path {
        transition: stroke 0.3s;
      }
    }
  `}

  ${({ $color }) => !$color ? "" : `
    svg path {
      stroke: ${$color};
    }
  `}
`

const Icon: FC<IconProps> = ({
  color,
  className = "",
  id,
  size = "16px",
  type
}) => (
  <IconReactSVG
    $color={color}
    $size={size}
    className={`Icon Icon--${type} ${className}`}
    id={id}
    src={`/icons/${type}.svg`}
  />
)

export default Icon
