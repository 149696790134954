import { Script } from "gatsby"
import React, { ReactElement } from "react"

const PARTNERSTACK_SCRIPT = `
(function() {var gs = document.createElement('script');gs.src = 'https://snippet.growsumo.com/growsumo.min.js';gs.type = 'text/javascript';gs.async = 'true';gs.onload = gs.onreadystatechange = function() {var rs = this.readyState;if (rs && rs != 'complete' && rs != 'loaded') return;try {growsumo._initialize('pk_0rhtyB7rzGvTz1gmuowOBOplo3zIHfGH');if (typeof(growsumoInit) === 'function') {growsumoInit();}} catch (e) {}};var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(gs, s);})();
`

const PartnerStackScript = (): ReactElement => (
  <Script
    id="PartnerStackScript"
    dangerouslySetInnerHTML={{ __html: PARTNERSTACK_SCRIPT }}
  />
)

export default PartnerStackScript
