import styled from "styled-components"

import { MEDIA_QUERIES } from "@constants"

export const HubspotFormDIV = styled.div`
  width: 100%;
  
  .submit-button {
    width: 100%;
  }

  .flex {
    gap: var(--spacing--XS);

    .flex-item--2 {
      flex: 0 0 calc(50% - var(--spacing--XS) / 2);
    }
  }

  form.flex {
    flex-direction: column;
  }

  label {
    cursor: pointer;  
    display: block;

    &.flex {
      display: flex;
    }

    span {
      display: block;
    }

    input,
    textarea {
      vertical-align: bottom;
    }
  }

  .small-text {
    &,
    p {
      font-size: var(--font-size--S);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: underline;
    }

    .is-compact& {
      &,
      p {
        font-size: var(--font-size--XS);
      }
    }
  }

  ${MEDIA_QUERIES.M} {
    &.is-email-catcher {
      .email-field {
        display: flex;
        flex-wrap: nowrap;
        gap: 0;

        label {
          flex: 1;
        }

        input[type=email] {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
          
          &:focus {
            box-shadow: none;
          }
        }

        .submit-button {
          width: auto;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
`
