import styled from "styled-components"

import { MEDIA_QUERIES } from "@constants"

export const ContentTableWrapperDIV = styled.div`
  position: relative;
  width: min(var(--container-width), calc(var(--text-max-width) + var(--spacing--XL) * 2));
  margin: 0 auto var(--spacing--M);
`

export const ContentTableScrollerDIV = styled.div`
  --ContentTable--ghost-area: 20px;
  width: calc(100% + var(--ContentTable--ghost-area) * 2);
  padding: 0 var(--ContentTable--ghost-area);
  margin: 0 calc(var(--ContentTable--ghost-area) * -1);
  overflow: scroll;
  scrollbar-width: none;
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    black var(--ContentTable--ghost-area),
    black calc(100% - var(--ContentTable--ghost-area)),
    transparent 100%
  );

  &::-webkit-scrollbar {
    display: none;
  }
`

export const ContentTableTABLE = styled.table`
  background: white;
  border-collapse: collapse;
  border: none;
  min-width: 720px;
  table-layout: fixed;
  width: 100%;

  thead th {
    background: var(--color--charcoal-200);
    font-size: var(--font-size--M);
  }

  tr:nth-child(even) td {
    background: var(--color--charcoal-100);

    .no-zebra& {
      background: transparent;
    }
  }

  td,
  th {
    padding: 12px;
    text-align: left;
    margin-bottom: -1px;

    ${MEDIA_QUERIES.S} {
      vertical-align: top;
      margin-bottom: 0;
    }
  }

  td {
    border: 1px solid var(--color--charcoal-300);

    &,
    p {
      font-size: var(--font-size--S);
    }
  }

  th {
    font-weight: 500;
    vertical-align: bottom;
    border: 1px solid var(--color--charcoal-400);
  }

  h1, h2, h3, h4 {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  ul li {
    font-size: var(--font-size--S);
  }

  img {
    margin: 30px 0;
  }
`
