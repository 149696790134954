import React, { FC } from 'react'
import { Link } from 'gatsby'
import { ReactSVG } from 'react-svg'

import {
  SiteFooterSmallFOOTER,
  SiteFooterSmallColumnsDIV,
} from './SiteFooterSmall.styles'

const SiteFooterSmall: FC = () => (
  <SiteFooterSmallFOOTER className="SiteFooter" role="contentinfo">
    <div className="container">
      <SiteFooterSmallColumnsDIV>
        <Link to="/">
          <ReactSVG src="/images/components/SiteNav/SiteNav--logo.svg" />
        </Link>
        <span className="type--text-XS color--text-light margin-bottom--0">&copy; {(new Date()).getFullYear()} Help&nbsp;Scout</span>
      </SiteFooterSmallColumnsDIV>
    </div>
  </SiteFooterSmallFOOTER>
)

export default SiteFooterSmall
