import TextLink, { TEXT_LINK_SIZES } from "@components/TextLink/TextLink"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { FC } from "react"
import styled from "styled-components"
import xss from "xss"

type SiteNavDrawerFooterCtaProps = {
  image?: IGatsbyImageData | null,
  tabIndex?: number,
  text?: string | null,
  title?: string | null,
  url?: string | null,
}

const SiteNavDrawerFooterCtaLINK = styled(Link)`
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex: 0 0 100%;
  gap: var(--spacing--base-1);
  margin-top: calc(var(--spacing--M) - var(--SiteNav--drawer-column--gap-y));

  .gatsby-image-wrapper {
    aspect-ratio: 16/9;
    border-radius: var(--border-radius--S);
    border: 0.5px solid rgba(var(--color--charcoal-1200-rgb), 0.08);
    box-shadow: var(--elevation--XS);
    width: max(calc(var(--spacing--base-1) * 6), 80px);
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const SiteNavDrawerFooterCta: FC<SiteNavDrawerFooterCtaProps> = ({
  image,
  tabIndex,
  text,
  title,
  url,
}) => {
  if (!title || !url) return null

  const isExternalLink = url.includes("//")

  return (
    <SiteNavDrawerFooterCtaLINK
      as={isExternalLink ? "a" : undefined}
      className="SiteNavDrawerFooterCta"
      href={isExternalLink ? url : undefined}
      tabIndex={tabIndex}
      to={isExternalLink ? undefined : url}
    >
      {image && (
        <GatsbyImage
          alt={title}
          image={image}
        />
      )}
      <div className="text">
        <h6
          className="type--headline-medium-XS margin-bottom--0"
          dangerouslySetInnerHTML={{ __html: xss(title) }}
        />
        <TextLink size={TEXT_LINK_SIZES.XS}>{text}</TextLink>
      </div>
    </SiteNavDrawerFooterCtaLINK>
  )
}

export default SiteNavDrawerFooterCta
