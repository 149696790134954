import { BREAKPOINTS } from "@constants"

/*
  Environment Check Utilities
*/
export const isProduction = (): boolean => process.env.GATSBY_VERCEL_ENV === "production"

export const isClientSide = (): boolean => typeof window !== "undefined"

export const isMobile = (): boolean => isClientSide() && window.innerWidth < BREAKPOINTS.L // `< BREAKPOINTS.L`

export const isTouchDevice = (): boolean =>
  isClientSide() && (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0
  )
