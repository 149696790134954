import React, { FC } from "react"
import { Script } from "gatsby"

declare global {
  interface Window {
    Beacon: any
  }
}

const Beacon: FC = () => (
  <Script
    id={`beacon-script`}
    strategy="post-hydrate"
  >
    {`
!function(e,t,n){function a() { var e = t.getElementsByTagName("script")[0], n = t.createElement("script"); n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e) }if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({ method: t, options: n, data: a })},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  if (window.Beacon) {
    window.Beacon('init', '6a6682b1-2136-420c-876e-c75f034745cc');
    window.Beacon('reset');
  }
`}
  </Script>
)

export default Beacon
