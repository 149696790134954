import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import Icon, { ICON_TYPES } from '../Icon/Icon'

export enum POPOVER_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum POPOVER_INDICATOR_TYPES {
  DEFAULT = "INFO_ICON",
  INFO_ICON = "INFO_ICON",
  UNDERLINE = "UNDERLINE",
  LABEL = "LABEL",
}

export const InfoPopoverContentSPAN = styled.span<{ position?: POPOVER_POSITION }>`
  background: var(--color--charcoal-1200);
  border-radius: var(--border-radius--S);
  box-shadow: var(--elevation--S);
  color: var(--color--basics-white);
  display: block;
  left: 50%;
  min-width: max(284px, calc((var(--container-width) / 12 * 3) - (var(--spacing--M) * 9 / 12)));
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  text-wrap: balanced;
  transform: translate(-50%, 10%);
  transition: all 0.2s;

  &.type-LABEL {
    ${props => props.position === POPOVER_POSITION.TOP && css`
      bottom: 4em;
    `}
  }
  
  ${props => props.position === POPOVER_POSITION.TOP && css`
    bottom: calc(100% + 0.5em);
  `}

  ${props => props.position === POPOVER_POSITION.BOTTOM && css`
    top: calc(100% + 0.5em);
  `}

  &::after {
    border: 6px solid transparent;
    border-top-color: var(--color--charcoal-1200);
    content: '';
    display: block;
    height: 6px;
    left: 50%;
    position: absolute;
    width: 6px;

    ${props => props.position === POPOVER_POSITION.TOP && css`
      bottom: -10px;
      transform: translateX(-50%);
    `}

    ${props => props.position === POPOVER_POSITION.BOTTOM && css`
      top: -10px;
      transform: translateX(-50%) rotate(180deg);
    `}
  }
`

export const InfoPopoverSPAN = styled.span`
  display: inline-block;
  position: relative;
  cursor: help;

  svg path {
    stroke: var(--color--charcoal-600);
  }

  .label-UNDERLINE {
    position: relative;
    text-decoration: var(--color--charcoal-300) underline solid 1px;
    text-underline-offset: 0.3em;
  }

  &:hover {
    svg path {
      stroke: var(--color--charcoal-700);
    }
    ${InfoPopoverContentSPAN} {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`

type InfoPopoverProps = {
  children: string,
  displayType: POPOVER_INDICATOR_TYPES,
  label?: string, // necessary for UNDERLINE & LABEL displayTypes
  position?: POPOVER_POSITION,
}

const InfoPopover: FC<InfoPopoverProps> = ({
  children,
  position = POPOVER_POSITION.TOP,
  displayType = POPOVER_INDICATOR_TYPES.DEFAULT,
  label,
}) => (
  <InfoPopoverSPAN>
    {
      displayType === POPOVER_INDICATOR_TYPES.UNDERLINE
        ? <span className={`label-${displayType}`} dangerouslySetInnerHTML={{ __html: label || "" }} />
        : displayType === POPOVER_INDICATOR_TYPES.LABEL
          ? <label className={`label-${displayType}`} dangerouslySetInnerHTML={{ __html: label || "" }} />
          : <Icon size="24px" type={ICON_TYPES["information"]} />
    }
    <InfoPopoverContentSPAN
      className="font-size--S font-family--sans line-height--snug margin-bottom-0"
      dangerouslySetInnerHTML={{ __html: children }}
      position={position || POPOVER_POSITION.TOP}
    />
  </InfoPopoverSPAN>
)

export default InfoPopover
